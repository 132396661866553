import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Emitter from '../utils/eventEmitter';
import {isExpired} from '../utils/accountFunctions';
import {makeDateString} from '../utils/dataFunctions';
import SubscriptionRecurringDialog from './SubscriptionRecurringDialog';
import {PLANS} from '../data/plans';

const SubscriptionsDisplay = ({
                                  list,
                                  title,
                                  includeSelf = false,
                                  showAddBtn = true,
                                  username = null
                              }) => {

    const {pathname} = useLocation();
    const [subscriptionlist, setSubscriptionList] = useState([]);
    const [recurringDialog, setRecurringDialog] = useState({
        subscription: null,
        requestRecurring: true,
        open: false
    });

    // Effect: Update table upon Subscriptions data
    useEffect(
        () => {
            const updateList = () => {
                const updatedList = [];
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    // Add a value for Recurring Switch
                    item.requestRecurring = item.recurring;
                    updatedList.push(item);
                }
                setSubscriptionList(updatedList);
            };

            if (list && list.length > 0) {
                return updateList();
            }

        }, [list]
    );

    // Event: Open Recurring Dialog with requested change
    const handleRecurringChange = (subscription, e) => {
        const idx = subscriptionlist.findIndex(r => r.username === subscription.username);
        if (idx !== -1) {
            subscriptionlist[idx].requestRecurring = e.target.checked;
        }
        setRecurringDialog({
            subscription: subscription,
            requestRecurring: e.target.checked,
            open: true
        });
    };

    // Event: Close Recurring Dialog without updating the Subscription
    const handleRecurringCancel = (subscription) => {
        // Reset the Switch for the Subscription back to its original state
        const username = subscription?.username ?? '';
        const idx = subscriptionlist.findIndex(r => r.username === username);
        if (idx !== -1) {
            subscriptionlist[idx].requestRecurring = subscriptionlist[idx].recurring;
        }
        // Close dialog
        setRecurringDialog({
            subscription: null,
            requestRecurring: true,
            open: false
        });
    };

    // Event: Recurring Dialog has completed its action
    const handleRecurringSaved = (subscription) => {
        setRecurringDialog({
            subscription: null,
            requestRecurring: true,
            open: false
        });
    };

    const handleResubscribeClick = () => {
        if (pathname.indexOf('/subscription') !== -1) {
            window.scrollTo(0, 0);
            // Send analytics event
            Emitter.emit('analyticsEvent', {name: 'resubscribeUser'});
        }
    };

    const setRows = () => {
        return (
            subscriptionlist.map((item, index) => {
                if (includeSelf || (item.username !== item.billing_account)) {

                    const expired = (item.active_until) ? isExpired(item.active_until) : true;
                    const activeUntilStr = (expired) ? '(Expired)' : makeDateString(item.active_until);
                    const plan = PLANS.find(r => r.id === item.subscription_type_id);
                    const title = (plan) ? plan.title : item.subscription_type_id;
                    let isDay;
                    let query = '';
                    if (plan) {
                        isDay = (plan.tag === 'day');
                        query = '?plan=' + plan.tag + '&user=' + item.username;
                    } else {
                        isDay = (item.subscription_type_id.indexOf('day') !== -1);
                    }
                    const showSubscribe = (expired);

                    return (
                        <tr key={`subscription-${item.username}-${index}`}>
                            <td>
                                {item.username}
                                {(username && username === item.username) ? ' (your account)' : ''}
                            </td>
                            <td>{title}</td>
                            <td>
                                {activeUntilStr}
                            </td>
                            <td>
                                {showSubscribe &&
                                <Link to={`/subscription${query}`}
                                      title="Re-Subscribe"
                                      onClick={() => handleResubscribeClick()}
                                      className="btn btn-outline-primary btn-xs ml-2"
                                >
                                    Re-Subscribe
                                </Link>
                                }
                                {!showSubscribe && !isDay &&
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={item.requestRecurring}
                                        onChange={(e) => handleRecurringChange(item, e)}
                                    />
                                    <span className="slider">
                                    </span>
                                </label>
                                }
                            </td>
                        </tr>
                    )
                }
                return (
                    <tr key={`subscription-empty-${index}`}>
                    </tr>
                )
            })
        )
    };

    if (!subscriptionlist || subscriptionlist.length === 0) {
        return (
            <div>
            </div>
        )
    }

    const titleStr = (title) ? title : 'Subscriptions You Manage';

    return (
        <>
            <section className="section pt-2 pb-2">
                <div className="container">

                    <h3>{titleStr}</h3>

                    <p>
                        Below are all Subscriptions billed to your Account.
                    </p>

                    <div className="table-container wide subscriptions">
                        <table className="fixed-header">
                            <thead>
                            <tr>
                                <th>User</th>
                                <th>Subscription</th>
                                <th>Active Until</th>
                                <th>Recurring</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[...setRows()]}
                            </tbody>
                        </table>
                    </div>

                    {showAddBtn &&
                    <Link to="/subscription" title="Add More Subscriptions" className="btn btn-primary">
                        Add More Subscriptions
                    </Link>
                    }
                </div>
            </section>

            <SubscriptionRecurringDialog
                subscription={recurringDialog.subscription}
                requestRecurring={recurringDialog.requestRecurring}
                open={recurringDialog.open}
                isSelf={false}
                onCancelFunc={(e) => handleRecurringCancel(e)}
                onSavedFunc={(e) => handleRecurringSaved(e)}
            />
        </>
    )
};

export default SubscriptionsDisplay;