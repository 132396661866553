import React from 'react';
import AmericanExpress from '../css/images/card-American-Express-logo.png';
import Blank from '../css/images/card-blank.png';
import Discover from '../css/images/card-Discover-logo.png';
import Maestro from '../css/images/card-Maestro-logo.png';
import MasterCard from '../css/images/card-Mastercard-logo.png';
import UnionPay from '../css/images/card-UnionPay-logo.png';
import VISA from '../css/images/card-VISA-logo.png';

const CreditCardLogo = ({card}) => {
    return (
        <>
            <img src={AmericanExpress} alt="American Express" style={{opacity: (card === 'american-express') ? 1 : 0}}/>
            <img src={Discover} alt="Discover" style={{opacity: (card === 'discover') ? 1 : 0}}/>
            <img src={Maestro} alt="Maestro" style={{opacity: (card === 'maestro') ? 1 : 0}}/>
            <img src={MasterCard} alt="Mastercard" style={{opacity: (card === 'mastercard') ? 1 : 0}}/>
            <img src={UnionPay} alt="UnionPay" style={{opacity: (card === 'unionpay') ? 1 : 0}}/>
            <img src={VISA} alt="VISA" style={{opacity: (card === 'visa') ? 1 : 0}}/>
            <img src={Blank} alt="Credit Card" style={{opacity: 0}}/>
        </>
    )
};

export default CreditCardLogo;
