import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import Emitter from './eventEmitter';
import {setAccountAccessStatus, setKeycloakReady, setTokens, setUser} from '../store/actions';
import {getUserInfoFromToken} from './tokenFunctions';

/**
 * Component for receiving and updating Keycloak events and tokens
 */
const KeycloakHandler = (props) => {

    // Effect: Handle event for Keycloak Ready
    useEffect(
        () => {
            const setKeycloakIsReady = () => {
                props.setKeycloakReady();
            };

            // Add a listener to store when Keycloak is ready
            Emitter.on('keycloakReady', setKeycloakIsReady);

            return () => Emitter.off('keycloakReady', setKeycloakIsReady);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    // Effect: Handle event for Keycloak Access Token
    useEffect(
        () => {
            /**
             * Store token in Redux once received
             *
             * @param props
             * @param token
             */
            const storeToken = (props, token) => {
                if (token) {
                    const tokenUser = getUserInfoFromToken(token);
                    const userObj = {
                        email: tokenUser.email,
                        family_name: tokenUser.family_name,
                        given_name: tokenUser.given_name,
                        groups: tokenUser.groups,
                        name: tokenUser.name,
                        preferred_username: tokenUser.preferred_username,
                    };

                    // Store token in Redux
                    props.setTokens({token: token, noToken: false});
                    // Store user information in Redux
                    props.setUser(userObj);
                    // Set Authorization header for all Axios calls
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                }
            };

            // Add a listener to store token when received by Keycloak Provider
            Emitter.on('accessToken', (e) => storeToken(props, e));

            return () => Emitter.off('accessToken', (e) => storeToken(props, e));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    // Effect: Handle event for undefined Keycloak Token
    useEffect(
        () => {
            const handleNoToken = () => {
                props.setTokens({
                    token: null,
                    noToken: true
                });
            };

            // Add a listener to handle undefined token
            Emitter.on('noToken', handleNoToken);

            return () => Emitter.off('noToken', handleNoToken);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    // Effect: Handle event for No Access / Denied
    useEffect(
        () => {
            const setAccess = (error) => {
                props.setAccountAccessStatus(error);
            };

            // Add a listener to store if there is an authentication error
            Emitter.on('authError', (e) => setAccess(e));

            return () => Emitter.off('authError', (e) => setAccess(e));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    return (
        <>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        tokens: {
            token: state.tokens.token
        }
    };
};

const mapDispatchToProps = {
    setAccountAccessStatus: setAccountAccessStatus,
    setKeycloakReady: setKeycloakReady,
    setTokens: setTokens,
    setUser: setUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KeycloakHandler);
