import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resources';
import {makeGetDataRequest} from './getRequest';
import {setPublicKey} from '../store/actions';

/**
 * Component for handling API calls for Subscriptions
 */
const APIKeyCheck = (props) => {

    const setPublicKey = props.setPublicKey;

    const processKey = useCallback(result => {
        setPublicKey({
            key: result.publicKey,
            realm: result.realm
        })
    }, [setPublicKey]);

    useEffect(
        () => {
            const getKey = () => {
                const request = makeGetDataRequest(resources, 'PublicKey', 'GET_ONE', null);
                request.then(result => {
                    if (result) {
                        processKey(result);
                        if (result.message) {
                            return Promise.resolve(result);
                        }
                        return Promise.resolve(result);
                    } else {
                        console.log('Public Key Error: No result');
                        return Promise.reject('Challenges Retrieval failed');
                    }
                }).catch(error => {
                    console.log('Public Key Error: ', error);
                    return Promise.reject('Server Error');
                });
            };

            if (props.keycloakReady) {
                return getKey();
            }

        }, [props.keycloakReady, processKey]);

    return (
        <>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady
    };
};

const mapDispatchToProps = {
    setPublicKey: setPublicKey,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIKeyCheck);