import React from 'react';
import APIKeyCheck from './APIKeyCheck';
import APIBilling from './APIBilling';
import APISubscription from '../utils/APISubscription';

/**
 * Container for automatic API components
 *
 * API to get Authorize.net Public Key
 * API to get User Subscriptions
 *
 */
const APIComponents = ({ready, user}) => {
    return (
        <>
            <APIKeyCheck/>
            <APIBilling ready={ready} user={user}/>
            <APISubscription ready={ready} user={user}/>
        </>
    )
};

export default APIComponents;
