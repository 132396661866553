export const PLANS = [
    {
        id: 'plan_day_v1',
        tag: 'day',
        name: 'Day Plan ($4.95)',
        title: 'Day Plan',
        description: 'The Day Plan is ideal for small day projects or a GPULab evaluation. It does not recur.',
        duration_hours: 0,
        duration_days: 1,
        duration_months: 0,
        duration_years: 0,
        cost_pennies: 495,
        can_recur: false
    },
    {
        id: 'plan_weekly_v1',
        tag: 'week',
        name: 'Weekly Plan ($14.95 / 7 days)',
        title: 'Weekly Plan',
        description: 'Recurs every seven days.',
        duration_hours: 0,
        duration_days: 7,
        duration_months: 0,
        duration_years: 0,
        cost_pennies: 1495,
        can_recur: true
    },{
        id: 'plan_monthly_v1',
        tag: 'month',
        name: 'Monthly Plan ($39.95 / month)',
        title: 'Monthly Plan',
        description: 'Recurs every month on the same day.',
        duration_hours: 0,
        duration_days: 0,
        duration_months: 1,
        duration_years: 0,
        cost_pennies: 3995,
        can_recur: true
    }
];

export const PLAN_FEATURES = [
    {
        id: 'plan_day_v1',
        features: [
            '1 NVIDIA K80 GPU Core',
            '12 GB of GPU RAM',
            '2 vCPU Cores',
            '16 GB CPU RAM',
            '25 GB of Personal Storage',
            'Nonrecurring'
        ]
    },
    {
        id: 'plan_weekly_v1',
        features: [
            '1 NVIDIA K80 GPU Core',
            '12 GB of GPU RAM',
            '2 vCPU Cores',
            '16 GB CPU RAM',
            '25 GB of Personal Storage',
            'Recurring',
            'Cancel Any Time'
        ]
    },
    {
        id: 'plan_monthly_v1',
        features: [
            '1 NVIDIA K80 GPU Core',
            '12 GB of GPU RAM',
            '2 vCPU Cores',
            '16 GB CPU RAM',
            '25 GB of Personal Storage',
            'Recurring',
            'Cancel Any Time'
        ]
    }
];