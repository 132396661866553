import Config from '../config';

/**
 * Get current body classes
 *
 * @return {DOMTokenList}
 */
export const getBodyClasses = () => {
    return document.body.classList;
};

/**
 * Determine if body has a specific class
 *
 * @param className
 * @return {boolean}
 */
export const bodyHasClass = (className) => {
    return document.body.classList.contains(className);
};

/**
 * Add a class to the body
 *
 * @param classes
 */
export const setBodyClasses = (classes) => {
    clearBodyClasses();
    document.body.classList.add(...classes);
};

/**
 * Remove all classes from body
 */
const clearBodyClasses = () => {
    let classList = getBodyClasses();
    while (classList.length > 0) {
        classList.remove(classList.item(0));
    }
};

/**
 * Set body classes from listed routes
 *
 * @param location
 * @param isAuthenticated
 */
export const setPathElements = (location, isAuthenticated) => {
    if (location) {
        let pathname = location.pathname;
        let hash = location.hash;
        let classArr = [];
        if (pathname && pathname.indexOf('/') !== -1) {
            let pathArray = pathname.split('/');
            if (pathArray[1] === '#') {
                // Add all path elements
                for (let p = 2; p < pathArray.length; p++) {
                    if (pathArray[p] !== '') {
                        classArr.push(pathArray[p].replace('%20', '-'));
                    }
                }
            } else {
                // For Pages without /x/, use the first value
                if (pathArray[1] !== '') {
                    classArr.push(pathArray[1]);
                }
                for (let p = 1; p < pathArray.length - 1; p++) {
                    if (pathArray[p] !== '') {
                        classArr.push(pathArray[p].replace('%20', '-'));
                    }
                }
            }
            // Add any hash elements
            let hashArray = hash.split('/');
            for (let h = 1; h < hashArray.length; h++) {
                classArr.push(hashArray[h].replace('%20', '-'));
            }
        } else {
            classArr.push(pathname);
        }

        // Set a class for the body based on route
        if (pathname === '/') {
            classArr.push('home');
        }

        // Add a class for authenticated state
        if (isAuthenticated) {
            classArr.push('authenticated')
        }

        setBodyClasses(classArr);
    }
};

/**
 * Extract location elements from window
 *
 * @returns {{search, route: string, href: *, hash}}
 */
export const getLocationElements = () => {
    const href = window.location.href;
    const hash = window.location.hash;
    const route = hash.substr(1);
    return {
        hash: window.location.hash,
        href: href,
        route: route,
        search: window.location.search
    };
};

/**
 * Find title of Page from location pathname
 *
 * @param path
 * @returns {*}
 */
export const getPageTitle = (path) => {
    const page = Config.pages.find(r => path.indexOf(r.path) !== -1);
    return (page) ? page.title : path;
};