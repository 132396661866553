import React from 'react';
import {Link} from 'react-router-dom';

const NavHeader = (props) => {

    const readyAndAuthenticated = (props.keycloakReady && props.isAuthenticated);

    return (
        <ul className="nav-main">
            {readyAndAuthenticated &&
            <>
                <li>
                    <Link to="/" title="Dashboard">
                        Dashboard
                    </Link>
                </li>
                <li>
                    <Link to="/subscription" title="Subscriptions">
                        Subscriptions
                    </Link>
                </li>
                <li>
                    <Link to="/account" title="Account">
                        Account
                    </Link>
                </li>
            </>
            }
        </ul>
    )
};

export default NavHeader;
