import React from 'react';
import '../css/tooltip.css';

const Tooltip = ({title, content, position = 'top', width = 180}) => {

    const style = (width) ? {width: width} : {};

    return (
        <span className={position} style={style}>
            {title &&
            <span className="h4">{title}</span>
            }
            {content &&
            <span className="p">{content}</span>
            }
            <i>
            </i>
        </span>
    )
};

export default Tooltip;
