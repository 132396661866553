import React from 'react';
import BaseInput from '../fields/BaseInput';
import NumberInput from '../fields/NumberInput';
import SelectInput from '../fields/SelectInput';
import FormError from './FormError';
import {COUNTRIES} from '../../data/countries';
import {US_STATES} from '../../data/usStates';

export default function BillingFields({register, errors, formState, getValues, defaultValues, changeFunc}) {

    const handleFieldChange = (e) => {
        if (typeof changeFunc === 'function') {
            changeFunc(null, '');
        }
    };

    const countryValue = getValues('billing_country');

    return (
        <>
            {/* First & Last Name */}
            <div className="row">
                <div className="col col-md-6">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-first-name" name="billing_first_name"
                            label="First Name *" placeholder="First Name *"
                            aria-describedby="error-billing_first_name-required"
                            aria-invalid={errors.billing_first_name ? 'true' : 'false'}
                            ref={register({
                                required: (
                                    <FormError id="error-billing_first_name-required" text="First Name is required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_first_name ? 'input-error' : ''}`}
                        >
                            {errors.billing_first_name && errors.billing_first_name.message}
                        </BaseInput>
                    </div>
                </div>
                <div className="col col-md-6">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-last-name" name="billing_last_name"
                            label="Last Name *" placeholder="Last Name *"
                            aria-describedby="error-billing_last_name-required"
                            aria-invalid={errors.billing_last_name ? 'true' : 'false'}
                            ref={register({
                                required: (
                                    <FormError id="error-billing_last_name-required" text="Last Name is required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_last_name ? 'input-error' : ''}`}
                        >
                            {errors.billing_last_name && errors.billing_last_name.message}
                        </BaseInput>
                    </div>
                </div>
            </div>

            {/* Address */}
            <div className="row">
                <div className="col col-xs-12">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-street" name="billing_street"
                            label="Street Address *" placeholder="Street Address *"
                            aria-describedby="error-billing_street-required"
                            aria-invalid={errors.billing_street ? 'true' : 'false'}
                            ref={register({
                                required: (
                                    <FormError id="error-billing_street-required" text="Address is required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_street ? 'input-error' : ''}`}
                        >
                            {errors.billing_street && errors.billing_street.message}
                        </BaseInput>
                    </div>
                </div>
            </div>

            {/* Country, City, State/Province, Postal Code */}
            <div className="row">
                <div className="col col-xs-6 col-md-3">
                    <div className="form-group">
                        <SelectInput
                            id="billing-country"
                            name="billing_country"
                            label="Country *"
                            optionPrompt="Country *"
                            options={COUNTRIES}
                            aria-describedby="error-billing_country-required"
                            aria-invalid={errors.billing_country ? 'true' : 'false'}
                            ref={register({
                                required: (<FormError id="error-billing_country-required" text="Required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className="form-input"
                        >
                            {errors.billing_country && errors.billing_country.message}
                        </SelectInput>
                    </div>
                </div>
                <div className="col col-xs-12 col-md-4">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-city" name="billing_city"
                            label="City *" placeholder="City *"
                            aria-describedby="error-billing_city-required"
                            aria-invalid={errors.billing_city ? 'true' : 'false'}
                            ref={register({
                                required: (<FormError id="error-billing_city-required" text="Required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_city ? 'input-error' : ''}`}
                        >
                            {errors.billing_city && errors.billing_city.message}
                        </BaseInput>
                    </div>
                </div>
                <div className="col col-xs-6 col-sm-3">
                    <div className="form-group">
                        {countryValue === 'US' &&
                        <SelectInput
                            id="billing-state"
                            name="billing_state"
                            label="State *"
                            optionPrompt="State *"
                            options={US_STATES}
                            aria-describedby="error-billing_state-required"
                            aria-invalid={errors.billing_state ? 'true' : 'false'}
                            ref={register({
                                required: (<FormError id="error-billing_state-required" text="Required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className="form-input"
                        >
                            {errors.billing_state && errors.billing_state.message}
                        </SelectInput>
                        }
                        {countryValue !== 'US' &&
                        <BaseInput
                            type="text"
                            id="billing-state" name="billing_state"
                            label="State/Province" placeholder="State/Province"
                            ref={register}
                            changeFunc={(e) => handleFieldChange(e)}
                            className="form-input"
                        >
                        </BaseInput>
                        }
                    </div>
                </div>
                <div className="col col-xs-6 col-sm-3 col-md-2">
                    <div className="form-group">
                        {countryValue === 'US' &&
                        <NumberInput
                            id="billing-postal-code" name="billing_postal_code"
                            label="Postal Code *" placeholder="Postal Code *"
                            aria-describedby="error-billing_postal_code-required"
                            aria-invalid={errors.billing_postal_code ? 'true' : 'false'}
                            ref={register({
                                required: (<FormError id="error-billing_postal_code-required" text="Required"/>)
                            })}
                            min={1}
                            max={99999}
                            step={1}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_postal_code ? 'input-error' : ''}`}
                        >
                            {errors.billing_postal_code && errors.billing_postal_code.message}
                        </NumberInput>
                        }
                        {countryValue !== 'US' &&
                        <BaseInput
                            type="text"
                            id="billing-postal-code" name="billing_postal_code"
                            label="Postal Code *" placeholder="Postal Code *"
                            aria-describedby="error-billing_postal_code-required"
                            aria-invalid={errors.billing_postal_code ? 'true' : 'false'}
                            ref={register({
                                required: (<FormError id="error-billing_postal_code-required" text="Required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_postal_code ? 'input-error' : ''}`}
                        >
                            {errors.billing_postal_code && errors.billing_postal_code.message}
                        </BaseInput>
                        }
                    </div>
                </div>
            </div>

            {/* Email */}
            <div className="row">
                <div className="col col-xs-12 col-md-6">
                    <div className="form-group mb-5">
                        <BaseInput
                            type="text"
                            id="billing-email" name="billing_email"
                            label="Email Address *" placeholder="Email Address *"
                            aria-describedby="error-billing_email-required"
                            aria-invalid={errors.billing_email ? 'true' : 'false'}
                            ref={register({
                                required: (
                                    <FormError id="error-billing_email-required" text="An Email Address is required"/>),
                                pattern: {
                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                    message: (<FormError id="error-billing_email-pattern"
                                                         text="Please enter a valid email address."/>)
                                }
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_email ? 'input-error' : ''}`}
                        >
                            {errors.billing_email && errors.billing_email.message}
                        </BaseInput>
                    </div>
                </div>
            </div>

            {/* PO */}
            <p className="instructions">
                If you are adding Billing Information for an organization, you can add a Purchase Order here.
            </p>
            <div className="row">
                <div className="col col-xs-6 col-md-3">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-po" name="billing_po"
                            label="Purchase Order" placeholder="Purchase Order"
                            ref={register}
                            changeFunc={(e) => handleFieldChange(e)}
                            className="form-input"
                        >
                        </BaseInput>
                    </div>
                </div>
            </div>
        </>
    )
}
