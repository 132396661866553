import React from 'react';
import {toCurrency} from '../utils/dataFunctions';

const AddedSubscriptionsDisplay = ({list, onRemoveFunc}) => {

    const handleRemove = (item) => {
        if (typeof onRemoveFunc === 'function') {
            onRemoveFunc(item);
        }
    };

    const setRows = () => {
        return (
            list.map((item, index) => {
                return (
                    <tr key={`added-subscription-${item.username}-${index}`}>
                        <td>{item.username}</td>
                        <td>{item.subscription_type_title}</td>
                        <td className="align-right">{item.cost_string}</td>
                        <td className="text-center sm">
                            <button className="btn btn-outline-primary btn-xs"
                                    onClick={() => handleRemove(item)}
                            >
                                Remove
                            </button>
                        </td>
                    </tr>
                )
            })
        )
    };

    const getTotalString = () => {
        let total = 0;
        for (let i = 0; i < list.length; i++) {
            total += parseInt(list[i].cost_pennies, 10);
        }
        return '$' + toCurrency(total, true);
    };

    if (!list || list.length === 0) {
        return (
            <div/>
        )
    }

    return (
        <div className="row mb-4">
            <div className="col col-xs-12 col-md-9">
                <table className="bg-page vertical-center">
                    <tbody>
                    {[...setRows()]}
                    <tr className="bg-none border-none">
                        <td>
                        </td>
                        <td className="align-right">Total</td>
                        <td className="align-right">{getTotalString()}</td>
                        <td>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default AddedSubscriptionsDisplay;