import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {setAccountAddSubscribers} from '../../store/actions';
import SubscribeFields from './SubscribeFields';
import SubscribeFormSubmission from './SubscribeFormSubmission';
import SubscriptionDisplay from '../SubscriptionDisplay';
import SubscriptionsDisplay from '../SubscriptionsDisplay';
import '../../css/form.css';

const SubscribeForm = (props) => {

    const user = props.user;
    const billing = (props.account.billing) ? props.account.billing : null;
    const billingStatus = (billing.billing_status) ? billing.billing_status : null;
    const subscription = (props.account.subscription) ? props.account.subscription : null;

    const [formStage, setFormStage] = useState('form');
    const [formMessage, setFormMessage] = useState({
        error: false,
        message: null
    });
    const [processSubmit, setProcessSubmit] = useState(false);

    const onFormChange = () => {
        setFormMessage({error: false, message: null});
    };

    // Set stage and notify form submission component
    const onFormSubmit = () => {
        setFormStage('saving');
        setProcessSubmit(true);
    };

    // Event: Form Submission component has completed
    const handleFormComplete = (e) => {
        setProcessSubmit(false);
        setFormStage((e.success) ? 'success' : 'fail');
        if (e.message) {
            setFormMessage({error: !e.success, message: e.message});
        }
    };

    return (
        <>
            <div className="form-section">
                <section className="section bg-light mb-0 pt-4 pb-4">
                    <div className="container">
                        <h3>Add Subscriptions</h3>

                        <SubscribeFields
                            needBilling={(!billing.username || billingStatus !== 'Ok')}
                            onFormChange={onFormChange}
                            onFormSubmit={onFormSubmit}
                            loading={(formStage === 'saving')}
                            formStage={formStage}
                        />
                        <SubscribeFormSubmission
                            user={user}
                            processSubmit={processSubmit}
                            onCompleteFunc={(e) => handleFormComplete(e)}
                        />

                        <p className={(formMessage.error) ? 'error' : ''}>
                            {formMessage.message &&
                            <>
                                {formMessage.message}
                            </>
                            }
                        </p>
                        {formMessage.error &&
                        <Link to="/account/billing" title="Update Billing"
                              className="btn btn-primary btn-sm"
                        >
                            Update Billing
                        </Link>
                        }
                    </div>
                </section>

                {billingStatus === 'Ok' && subscription.subscription_type &&
                <SubscriptionDisplay data={subscription}/>
                }

                {props.account?.billing?.subscriptions &&
                <SubscriptionsDisplay
                    list={props.account.billing.subscriptions}
                    includeSelf={true}
                    showAddBtn={false}
                    username={user.preferred_username}
                />
                }

            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account,
        user: state.user
    };
};

const mapDispatchToProps = {
    setAccountAddSubscribers: setAccountAddSubscribers
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribeForm);