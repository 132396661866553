const config = {
    version: '0.11.1',
    language: 'en-US',
    account: {
        access: true,
        accessStatus: null,
        billing: {},
        subscription: {},
        subscribersToAdd: []
    },
    pages: [
        {path: '/account/billing', title: 'Billing Information'},
        {path: '/account/history', title: 'Billing History'},
        {path: '/account', title: 'Account Landing'},
        {path: '/subscribe', title: 'Subscribe Landing'},
        {path: '/subscription', title: 'Subscribe'},
        {path: '/', title: 'GPULab Home'}
    ],
    publicKey: {
        key: null,
        realm: null
    },
    tokensDefault: {
        idToken: null,
        token: null,
        refreshToken: null,
        noToken: undefined
    },
    urls: {
        api_url: '',
        lab_url: ''
    },
    user: {
        email: '',
        family_name: '',
        given_name: '',
        groups: [],
        is_sysop: false,
        name: '',
        preferred_username: ''
    }
};

export default config;
