// Auth
export const LOG_OUT = 'LOG_OUT';
export const SET_KEYCLOAK_READY = 'SET_KEYCLOAK_READY';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_URLS = 'SET_URLS';
export const SET_USER = 'SET_USER';

// Account/Purchase
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const SET_ACCOUNT_ACCESS_STATUS = 'SET_ACCOUNT_ACCESS_STATUS';
export const SET_ACCOUNT_ADD_SUBSCRIBERS = 'SET_ACCOUNT_ADD_SUBSCRIBERS';
export const SET_ACCOUNT_BILLING = 'SET_ACCOUNT_BILLING';
export const SET_ACCOUNT_SUBSCRIPTION = 'SET_ACCOUNT_SUBSCRIPTION';

// UI
