import {
    LOG_OUT,
    SET_ACCOUNT_ACCESS_STATUS,
    SET_ACCOUNT_ADD_SUBSCRIBERS,
    SET_ACCOUNT_BILLING,
    SET_ACCOUNT_SUBSCRIPTION,
    SET_PUBLIC_KEY,
    SET_KEYCLOAK_READY,
    SET_TOKENS,
    SET_URLS,
    SET_USER
} from './actionTypes';

/* Auth / User ::::::::::::::::::::::::::::::::::::::::::::: */

export const setKeycloakReady = () => {
    return {
        type: SET_KEYCLOAK_READY
    }
};

export const setTokens = tokens => {
    return {
        type: SET_TOKENS,
        tokens
    };
};

export const setUser = obj => {
    return {
        type: SET_USER,
        obj
    };
};

export function logOutUser() {
    return {
        type: LOG_OUT
    }
}

/* Account / Billing ::::::::::::::::::::::::::::::::::::::::::::: */

export const setAccountAccessStatus = status => {
    return {
        type: SET_ACCOUNT_ACCESS_STATUS,
        status
    };
};

export const setAccountAddSubscribers = list => {
    return {
        type: SET_ACCOUNT_ADD_SUBSCRIBERS,
        list
    };
};

export const setAccountBilling = obj => {
    return {
        type: SET_ACCOUNT_BILLING,
        obj
    };
};

export const setAccountSubscription = obj => {
    return {
        type: SET_ACCOUNT_SUBSCRIPTION,
        obj
    };
};

export const setPublicKey = obj => {
    return {
        type: SET_PUBLIC_KEY,
        obj
    };
};

/* UI ::::::::::::::::::::::::::::::::::::::::::::: */

export const setUrls = urls => {
    return {
        type: SET_URLS,
        urls
    };
};
