import axios from 'axios';
import {getGetHeaders} from './headers';

/**
 * Make a get request with a specific resource
 *
 * @param resource
 * @param name
 * @param type
 * @param params
 * @return {Promise<*>}
 */
export async function makeGetDataRequest(resource, name, type, params) {
    let func;
    switch (type) {
        case 'GET_LIST':
            func = resource[name].GET_LIST(params);
            break;
        case 'GET_ONE':
            func = resource[name].GET_ONE(params);
            break;
        default:
            func = resource[name].GET_LIST(params);
    }
    try {
        return await getRequest(func.uri);
    } catch (e) {
        console.log('data error: ', e.message);
        return e;
    }
}

/**
 * Make GET request
 *
 * @param uri
 * @return {Promise<AxiosResponse<any>>}
 */
const getRequest = (uri) => {
    return axios({
        method: 'get',
        url: uri,
        headers: getGetHeaders(),
        maxBodyLength: Infinity,
        maxContentLength: Infinity
    }).then((response) => {
        if (response.data && response.data.payload) {
            // Payload of string or object
            return response.data.payload;
        } else if (response.data && response.data.payload === false) {
            // Payload of boolean
            return response.data.payload;
        } else if (response.data) {
            return response.data;
        } else if (response) {
            return response;
        }
    }).catch((error) => {
        if (error.response) {
            // Server response outside 2xx
            return error.response.data;
        } else if (error.request) {
            // No response
            return error.request;
        } else {
            return error;
        }
    });
};

export default getRequest();
