import React from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import BillingFormFull from '../partials/forms/BillingFormFull';
import PageTitle from '../partials/PageTitle';

const Billing = (props) => {

    const {keycloak} = useKeycloak();
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);

    if (!isAuthenticated && !props.keycloakReady) {
        return (
            <>
                <PageTitle title="Billing" classes="billing"/>
            </>
        )
    }

    return (
        <>
            <PageTitle title="Billing Information" classes="billing"/>
            <BillingFormFull/>
        </>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        account: state.account,
        keycloakReady: state.keycloakReady,
        tokens: {
            token: tokens.token
        }
    };
};

export default connect(
    mapStateToProps
)(Billing);
