import React from 'react';
import IconCheck from './images/IconCheck';
import IconClose from './images/IconClose';

const BooleanIcon = ({isTrue}) => {

    if (isTrue) {
        return (
            <div className="icon-boolean true">
                <IconCheck/>
            </div>
        )
    }

    return (
        <div className="icon-boolean false">
            <IconClose/>
        </div>
    )
};

export default BooleanIcon;
