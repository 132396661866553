import React from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import PageTitle from '../partials/PageTitle';
import SubscribeForm from '../partials/forms/SubscribeForm';

const Subscription = (props) => {

    const {keycloak} = useKeycloak();
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);

    if (!isAuthenticated) {
        return (
            <div/>
        )
    }

    return (
        <>
            <PageTitle title="Subscribe" classes="subscription"/>
            <SubscribeForm/>
        </>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        tokens: {
            token: tokens.token
        }
    };
};

export default connect(
    mapStateToProps
)(Subscription);
