import React from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import BillingHistory from '../partials/BillingHistory';
import PageTitle from '../partials/PageTitle';

const History = (props) => {

    const {keycloak} = useKeycloak();
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);

    if (!isAuthenticated) {
        return (
            <div/>
        )
    }

    // Display links based on Billing status
    const billing = props.account.billing;

    return (
        <>
            <PageTitle title="Billing History" classes="account"/>

            <div className="row text-center mb-4">
                <div className="col col-xs-12">
                    <Link to="/account/billing" className="btn btn-primary mr ml"
                          title="Billing and Payment Information">
                        Billing and Payment Information
                    </Link>
                </div>
            </div>

            {billing.username &&
            <BillingHistory billingData={billing}/>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        account: state.account,
        tokens: {
            token: tokens.token
        }
    };
};

export default connect(
    mapStateToProps
)(History);
