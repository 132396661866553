import React from 'react';
import BaseInput from '../fields/BaseInput';
import FormError from './FormError';

export default function ContactFields({register, errors, formState, getValues, defaultValues, changeFunc}) {

    const handleFieldChange = (e) => {
        if (typeof changeFunc === 'function') {
            changeFunc(null, '');
        }
    };

    return (
        <>
            {/* Contact Full Name */}
            <div className="row">
                <div className="col col-xs-12 col-md-8">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="contact-full-name" name="contact_full_name"
                            label="Full Name *" placeholder="Full Name *"
                            aria-describedby="error-contact_full_name-required"
                            aria-invalid={errors.contact_full_name ? 'true' : 'false'}
                            ref={register({
                                required: (
                                    <FormError id="error-contact_full_name-required" text="A Name is required"/>)
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.contact_full_name ? 'input-error' : ''}`}
                        >
                            {errors.contact_full_name && errors.contact_full_name.message}
                        </BaseInput>
                    </div>
                </div>
            </div>

            {/* Organization, Email, Phone */}
            <div className="row">
                <div className="col col-xs-12 col-md-4">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="contact-org-name" name="organization_name"
                            label="Organization Name" placeholder="Organization Name"
                            ref={register}
                            changeFunc={(e) => handleFieldChange(e)}
                            className="form-input"
                        >
                        </BaseInput>
                    </div>
                </div>
                <div className="col col-xs-6 col-md-4">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="contact-email" name="contact_email"
                            label="Email Address *" placeholder="Email Address *"
                            aria-describedby="error-contact_email-required"
                            aria-invalid={errors.contact_email ? 'true' : 'false'}
                            ref={register({
                                required: (
                                    <FormError id="error-contact_email-required" text="An Email Address is required"/>),
                                pattern: {
                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                    message: (<FormError id="error-contact_email-pattern"
                                                         text="Please enter a valid email address."/>)
                                }
                            })}
                            required
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.contact_email ? 'input-error' : ''}`}
                        >
                            {errors.contact_email && errors.contact_email.message}
                        </BaseInput>
                    </div>
                </div>
                <div className="col col-xs-6 col-md-4">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="contact-phone" name="contact_phone"
                            label="Phone" placeholder="Phone"
                            ref={register}
                            changeFunc={(e) => handleFieldChange(e)}
                            className="form-input"
                        >
                        </BaseInput>
                    </div>
                </div>
            </div>

        </>
    )

}