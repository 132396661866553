import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resources';
import {setAccountBilling} from '../store/actions';
import {makeGetDataRequest} from './getRequest';
import {convertCountryCode, sortArrayByKey} from './dataFunctions';
import Emitter from './eventEmitter';

/**
 * Component for handling API calls for Billing information
 */
const APIBilling = (props) => {

    const ready = props.ready;
    const user = props.user;
    const username = user.preferred_username;
    const setAccountBilling = props.setAccountBilling;

    const [processed, setProcessed] = useState(false);

    // Callback: Store User Billing data
    const processData = useCallback(data => {
        if (data.subscriptions && data.subscriptions.length > 0) {
            data.subscriptions = sortArrayByKey(data.subscriptions, 'username', 'ASC');
        }
        if (data.billing_country) {
            data.billing_country = convertCountryCode(data.billing_country);
        }
        setAccountBilling(data);
        setProcessed(true);
    }, [setAccountBilling]);

    // Effect: Get Billing data for logged-in User
    useEffect(
        () => {
            const getBilling = () => {
                const request = makeGetDataRequest(resources, 'Billing', 'GET_ONE');
                request.then(result => {
                    if (!result || result.status === false) {
                        // If user is not found:
                        processData({});
                        return Promise.resolve();
                    } else {
                        // Store data
                        processData(result);
                        return Promise.resolve(result);
                    }
                }).catch(error => {
                    console.log('User Billing Error: ', error);
                    processData({});
                    return Promise.reject('Server Error');
                });
            };

            if (ready && username && !processed) {
                return getBilling();
            }

        }, [ready, username, processData, processed]);

    // Effect: Refresh Billing data for logged-in User
    useEffect(
        () => {
            const refreshBilling = () => {
                setProcessed(false);
            };

            Emitter.on('checkBilling', refreshBilling);

            return () => Emitter.off('checkBilling', refreshBilling);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    return (
        <>
        </>
    )
};

const mapDispatchToProps = {
    setAccountBilling: setAccountBilling,
};

export default connect(
    null,
    mapDispatchToProps
)(APIBilling);