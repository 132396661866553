import React from 'react';

const PageTitle = ({title, classes = ''}) => {
    return (
        <section className="section pb-0">
            <div className="container text-center">
                <div className={`content ${classes}`}>
                    <h1 className="page-title">{title}</h1>
                </div>
            </div>
        </section>
    )
};

export default PageTitle;
