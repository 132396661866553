import React, {useState} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {isExpired} from '../utils/accountFunctions';
import {checkAuthentication} from '../utils/authFunctions';
import NavHeader from '../partials/NavHeader';
import NavHeaderUser from '../partials/NavHeaderUser';
import GPULabLogo from '../partials/images/GPULabLogo';
import IconMenu from '../partials/images/IconMenu';
import '../css/header.css';

const AppHeader = (props) => {

    const subscription = props.account?.subscription;
    const {keycloak} = useKeycloak();
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);
    const logoUrl = props?.urls?.site_url ?? '/';
    const expired = (subscription?.active_until) ? isExpired(subscription.active_until) : true;

    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <header className="top-bar">
                <div className="container">
                    <div className="navbar">
                        <div className="logo-container">
                            <a href={logoUrl} title="GPULab" rel="noopener noreferrer">
                                <GPULabLogo/>
                            </a>
                        </div>

                        <button className="navbar-toggler"
                                data-toggle="collapse"
                                data-target="nav-top"
                                aria-expanded={showMenu}
                                onClick={() => setShowMenu(!showMenu)}
                        >
                            <IconMenu/>
                        </button>

                        <div id="nav-top-mobile" className="nav-top-mobile">
                            {/* Mobile Launch button */}
                            <a href="https://lab.gpulab.io/" title="Launch GPULab Environment"
                               className={`btn btn-primary mobile-only${(expired) ? ' disabled' : ''}`}
                               target="_blank" rel="noopener noreferrer"
                            >
                                Launch GPULab Environment
                            </a>
                        </div>

                        <div id="nav-top" className={`nav-top ${(showMenu) ? 'show' : ''}`}>

                            {/* Top Main Navigation */}
                            <NavHeader keycloakReady={props.keycloakReady} isAuthenticated={isAuthenticated}/>

                            {/* Top User Top Navigation */}
                            <NavHeaderUser keycloakReady={props.keycloakReady} isAuthenticated={isAuthenticated}/>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        account: state.account,
        keycloakReady: state.keycloakReady,
        tokens: {
            token: tokens.token
        },
        urls: state.urls
    };
};

export default connect(
    mapStateToProps
)(AppHeader);
