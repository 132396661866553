import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';
import {RESOURCES as resources} from '../resources';
import {makeGetDataRequest} from '../utils/getRequest';
import {makeDateString, toCurrency} from '../utils/dataFunctions';
import BillingHistoryDetailItems from './BillingHistoryDetailItems';
import GPULabLogo from '../partials/images/GPULabLogo';
import IconClose from './images/IconClose';
import IconPrint from './images/IconPrint';
import LoadingIndicator from './LoadingIndicator';
import '../css/dialog.css';

const printStyle = `
@media print {
html, body {background: #ffffff; overflow: hidden;}
.content {-webkit-print-color-adjust: exact; margin: 10pt 10pt 0 10pt; padding: 0;}
.h4 {position: relative; width: calc(100% + 20pt); left: -10pt; min-height: 52pt; background-color: #edf6f5; margin: 0; padding: 0;}
.h5-container {padding: 10pt 0 6pt 0;}
.h5 {background: #efefef; font-size: 16px; font-weight: 600; margin: 0; padding: 8pt 0 8pt 8pt; border-top: 1px solid #999999; border-bottom: 1px solid #999999;}
.btns-top {display: none;}
.logo-print {display: block; position: absolute; top: 8pt; left: 4pt; width: 190pt;}
.logo-print svg { width: 100%; height: auto; vertical-align: top;}
.customer {display: block; position: absolute; top: 15pt; right: 6pt;}
.detail-footer {background-color: #edf6f5; margin: 15pt 0 0 0; padding: 8pt 8pt;}
.detail footer .copyright {margin: 1rem 0 0 0; padding: 1.5rem 0 0.5rem 0;}
.detail footer a {color: #0aa8a7;}
@page {size: portrait; page-break-after: auto;}
}
`;

const BillingHistoryDetailDialog = ({
                                        onCloseFunc,
                                        billingData,
                                        refId = '',
                                        open = false
                                    }) => {

    const dialogRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [detail, setDetail] = useState(null);

    const handlePrint = useReactToPrint({
        content: () => dialogRef.current,
        copyStyles: true,
        documentTitle: 'GPULab Transaction',
        pageStyle: printStyle
    });

    const data = billingData;

    const processData = useCallback(result => {
        let item = null;
        if (result && result.ref_id) {
            const req = result.request;
            const resp = result.response;
            const transReq = req?.details?.createTransactionRequest?.transactionRequest ?? null;
            const items = (transReq && transReq.lineItems) ? transReq.lineItems.lineItem : [];
            item = {
                billing_account: result.billing_account,
                ref_id: result.ref_id
            };
            item.amount = (resp.amount) ? resp.amount : 0;
            item.amountStr = (item.amount) ? toCurrency(item.amount, true) : '';
            item.card = (resp.account_number) ? resp.account_number.replace(/X/g, '') : '';
            item.cardType = (resp.account_type) ? resp.account_type : 'Card';
            item.date = (resp.added) ? resp.added : req.added;
            item.dateStr = (item.date) ? makeDateString(item.date) : '';
            item.description = transReq?.order?.description ?? '';
            item.error = resp.error;
            item.error_description = resp?.error_description ?? '';
            item.items = items;
            item.message_text = resp?.messages_message_text ?? '';
            item.po = transReq?.poNumber ?? '';
            item.reason = resp?.transReq ?? '';
        }
        setDetail(item);
    }, []);

    // Effect: Get Billing History data for requested reference ID
    useEffect(
        () => {
            const getBillingHistoryDetail = () => {
                const params = {
                    id: refId
                };
                const request = makeGetDataRequest(resources, 'BillingHistory', 'GET_ONE', params);
                request.then(result => {
                    if (!result || result.status === false || result.data === null) {
                        processData(null);
                        return Promise.resolve();
                    } else {
                        processData(result);
                        return Promise.resolve(result);
                    }
                }).catch(error => {
                    console.log('Billing History Detail Error: ', error);
                    processData(null);
                    return Promise.reject('Server Error');
                });
            };

            if (refId) {
                return getBillingHistoryDetail();
            }

        }, [refId, processData]);

    // Effect: Open or Close Dialog
    useEffect(
        () => {
            const updateOpen = () => {
                setIsOpen(open);
                if (!open) {
                    setDetail(null);
                }
            };
            return updateOpen(open);
        }, [open]
    );


    const handleClose = () => {
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const year = new Date().getFullYear();

    return (
        <div className={`dialog billing-detail ${(isOpen) ? 'open' : ''}`}>
            <div className="overlay" onClick={() => handleClose()}>
            </div>
            <div ref={dialogRef} className="content">

                <div className="h4">
                    <div className="logo-print print-only">
                        <GPULabLogo/>
                    </div>
                    <div className="customer">
                        {`Customer: ${data.contact_full_name}`}
                    </div>

                    <div className="btns-top">
                        <button className="btn btn-icon" onClick={() => handlePrint()}>
                            <IconPrint/>
                        </button>
                        <button className="btn btn-icon" onClick={() => handleClose()}>
                            <IconClose/>
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-12 h5-container">
                        <div className="h5">Transaction</div>
                    </div>
                </div>
                {!detail &&
                <LoadingIndicator color="dark" centered={true} size={36} active={true}/>
                }
                {detail &&
                <>
                    <div className="row">
                        <div className="col col-xs-12 col-sm-6">
                            <div className="row">
                                <div className="col col-xs-5">
                                    Transaction:<br/>
                                    Merchant:<br/>
                                    Description:
                                </div>
                                <div className="col col-xs-7">
                                    {refId}<br/>
                                    <span className="merchant">DEASIL WORKS INC</span><br/>
                                    {detail.description}
                                </div>
                            </div>
                        </div>

                        <div className="col col-xs-12 col-sm-6">
                            <div className="row">
                                <div className="col col-xs-5">
                                    Username:
                                </div>
                                <div className="col col-xs-7">
                                    {data.username}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }

                <div className="row">
                    <div className="col col-xs-12 h5-container">
                        <div className="h5">Billing Information</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-6">
                        {data.contact_full_name}<br/>
                        {data.billing_street}<br/>
                        {data.billing_city}, {data.billing_state} {data.billing_postal_code}<br/>
                        {data.billing_country}<br/>
                    </div>
                    <div className="col col-xs-6">
                        {data.organization_name}<br/>
                        {data.contact_phone}<br/>
                        {data.billing_email}<br/>
                        {detail && detail.po &&
                        <>
                            PO {detail.po}
                        </>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-12 h5-container">
                        <div className="h5">Order Items</div>
                    </div>
                </div>
                {detail &&
                <BillingHistoryDetailItems list={detail.items}/>
                }

                <div className="row">
                    <div className="col col-xs-12 h5-container">
                        <div className="h5">Payment Information</div>
                    </div>
                </div>
                {!detail &&
                <LoadingIndicator color="dark" centered={true} size={36} active={true}/>
                }
                {detail &&
                <>
                    <div className="row">
                        <div className="col col-xs-6 col-sm-5">
                            Date/Time:<br/>
                            Payment Method:<br/>
                            Transaction Status:<br/>
                        </div>
                        <div className="col col-xs-6 col-sm-7">
                            {detail.dateStr}<br/>
                            {detail.cardType}
                            {(detail.cardType) ? ' ending in ' + detail.card : ''}<br/>
                            {detail.error &&
                            <>
                                {detail.error_description}
                            </>
                            }
                            {!detail.error &&
                            <>
                                {detail.message_text}
                            </>
                            }
                        </div>
                    </div>
                </>
                }
                <div className="detail-footer print-only">
                    <div className="copyright">
                        <span>
                            &copy; {year} Deasil Works, Inc.  |  GPULab.io
                        </span>
                        <br/><br/>
                        <span>
                             Deasil Works, Inc.  |  (818) 945 0821  |  info@gpulab.io
                        </span>
                    </div>
                </div>
                <div className="page-break">
                </div>
            </div>
        </div>
    )
};

export default BillingHistoryDetailDialog;
