import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import Config from '../config';

const initialState = {
    account: Config.account,
    keycloakReady: false,
    publicKey: Config.publicKey,
    tokens: Config.tokensDefault,
    urls: Config.urls,
    user: Config.user
};

// Create a Redux store with initial state, Redux-Thunk and DevTools
const store = createStore(
    reducer,
    initialState,
    compose(
        applyMiddleware(thunk),
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    )
);

export default store;
