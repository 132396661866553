import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {setAccountAddSubscribers} from '../../store/actions';
import Emitter from '../../utils/eventEmitter';
import {RESOURCES as resources} from '../../resources';
import {makePostDataRequest} from '../../utils/postRequest';

const SubscribeFormSubmission = (props) => {

    const username = props.user?.preferred_username;
    const processSubmit = props.processSubmit;
    const onCompleteFunc = props.onCompleteFunc;
    const subscribersToAdd = props.account?.subscribersToAdd;

    // Callback: Send completed status and message back to parent
    const completeSubmission = useCallback((success, message, subscribingSelf) => {

        // Send event to refresh Billing and Subscription data
        Emitter.emit('checkBilling', true);
        if (subscribingSelf) {
            Emitter.emit('checkSubscription', true);
        }

        if (typeof onCompleteFunc === 'function') {
            onCompleteFunc({
                success: success,
                message: message
            });
        }
    }, [onCompleteFunc]);

    // Callback: Assemble data and submit form
    const handleSubmission = useCallback(() => {
        let subscribingSelf = false;
        if (subscribersToAdd.length) {

            // Assemble data
            const data = [];
            for (let i = 0; i < subscribersToAdd.length; i++) {
                data.push({
                    subscription_type_id: subscribersToAdd[i].subscription_type_id,
                    username: subscribersToAdd[i].username,
                });
                if (subscribersToAdd[i].username === username) {
                    subscribingSelf = true;
                }
            }

            const subscribeRequest = makePostDataRequest(resources, 'Subscription', 'POST', data);
            subscribeRequest.then(result => {
                if (result) {
                    let success = false;
                    let message = '';
                    if (result.data?.status === false) {
                        // Error: existing subscription (unlikely from Portal)
                        if (result.data.message?.indexOf('active subscription') !== -1) {
                            const activeUser = data.find(r => result.data.message.indexOf(r.username) !== -1);
                            const messageUser = (activeUser) ? 'user ' + activeUser.username : 'a user';
                            message = 'Sorry, ' + messageUser + ' already has an existing active Subscription.';
                        } else {
                            message = (result.data.message) ? result.data.message : 'Sorry, there was an issue subscribing.';
                        }
                    } else if (result.status === true) {
                        message = (data.length > 1)
                            ? 'Your subscriptions were successful.'
                            : 'Your subscription was successful.';
                        success = true;
                    }
                    completeSubmission(success, message, subscribingSelf);
                    return Promise.resolve(result);
                } else {
                    console.log('Save Subscribe no result: ', result);
                    completeSubmission(false, 'Sorry, there was an issue subscribing.', subscribingSelf);
                    return Promise.resolve();
                }
            }).catch(error => {
                console.log('Save Subscribe Error: ', error);
                completeSubmission(false, 'Sorry, there was an issue subscribing.', subscribingSelf);
                return Promise.reject('Server Error');
            });

        } else {
            completeSubmission(false, 'There are no subscribers to add.', subscribingSelf);
        }
    }, [subscribersToAdd, username, completeSubmission]);

    // Effect: If submit is required, submit the data
    useEffect(
        () => {
            const doSubmit = () => {
                handleSubmission();
            };

            if (processSubmit === true) {
                return doSubmit();
            }

        }, [processSubmit, handleSubmission]
    );

    return (
        <>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account,
        user: state.user
    };
};

const mapDispatchToProps = {
    setAccountAddSubscribers: setAccountAddSubscribers
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribeFormSubmission);