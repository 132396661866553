import React from 'react';
import Tooltip from '../partials/Tooltip';

const TooltipInfo = ({title, content, position = 'right', width = 180}) => {
    return (
        <span className="tooltip info">?
            <Tooltip title={title} content={content} position={position} width={width}/>
        </span>
    )
};

export default TooltipInfo;
