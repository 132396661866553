import React from 'react';
import {Link} from 'react-router-dom';
import Aside from './Aside';

const AccountStatusDisplay = ({billingStatus}) => {

    return (
        <section className="section mt-0 mb-0 pb-0">
            <div className="container">

                <h3>Your Billing Status</h3>

                <div className="row">
                    <div className="col col-xs-12">
                        {billingStatus === 'Ok' &&
                        <Aside type="success" title="Okay"
                               content={`Your Billing Status is okay.`}
                        />
                        }
                        {billingStatus !== 'Ok' &&
                        <>
                            <p>There is an issue with your Billing or Payment Information:</p>
                            <Aside type="error" title="Error"
                                   content={billingStatus}
                            />
                            <p>
                                Any Subscriptions you manage will not renew until
                                your <Link to="/account/billing">Billing and Payment</Link> information
                                is confirmed as valid.
                            </p>
                        </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
};

export default AccountStatusDisplay;