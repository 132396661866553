import React from 'react';

const IconClose = () => {
    return (
        <span className="icon close">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="0 0 115.2 115.2" enableBackground="new 0 0 115.2 115.2"
                 xmlSpace="preserve">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.95,12.668l8.716-8.727c5.268-5.257,13.887-5.257,19.155,0l25.783,25.783
	L83.375,3.941c5.269-5.257,13.887-5.257,19.155,0l8.726,8.727c5.258,5.268,5.258,13.887,0,19.155L85.474,57.595l25.782,25.783
	c5.258,5.268,5.258,13.887,0,19.154l-8.726,8.715c-5.269,5.27-13.887,5.27-19.155,0L57.603,85.477L31.82,111.247
	c-5.268,5.27-13.887,5.27-19.155,0l-8.716-8.715c-5.268-5.268-5.268-13.887,0-19.154l25.772-25.783L3.95,31.823
	C-1.318,26.555-1.318,17.936,3.95,12.668z"/>
            </svg>
        </span>
    )
};

export default IconClose;
