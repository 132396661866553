import React, {Component} from 'react';
import PropTypes from 'prop-types';

const styles = {
    indicator: {
        display: 'inline-block',
        margin: 0,
        verticalAlign: 'top'
    },
    centered: {
        display: 'block',
        textAlign: 'center',
        margin: '0 auto'
    },
    path_dark: {
        fill: '#0aa8a7'
    },
    path_light: {
        fill: '#edf6f5'
    },
};

class LoadingIndicator extends Component {

    render() {
        const {
            active,
            centered,
            color,
            size,
            speed
        } = this.props;

        const centerStyle = (centered) ? styles.centered : styles.indicator;
        const pathStyle = (color === 'light') ? styles['path_light'] : styles['path_dark'];
        const sizePx = size + 'px';
        const sizeStyle = {display: 'block', width: size, height: size};

        return (
            <span style={centerStyle}>
                <span style={sizeStyle}>
                    {active &&
                    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width={sizePx} height={sizePx}
                         viewBox="0 0 40 40"
                         enableBackground="new 0 0 40 40"
                         xmlSpace="preserve"
                    >
                        <path opacity="0.2" fill="#000" style={pathStyle}
                              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                        <path fill="#000" style={pathStyle}
                              d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z">
                            <animateTransform attributeType="xml"
                                              attributeName="transform"
                                              type="rotate"
                                              from="0 20 20"
                                              to="360 20 20"
                                              dur={speed}
                                              repeatCount="indefinite"/>
                        </path>
                    </svg>
                    }
                </span>
            </span>
        )
    }
}

LoadingIndicator.propTypes = {
    active: PropTypes.bool,
    centered: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.number,
    speed: PropTypes.string
};

LoadingIndicator.defaultProps = {
    centered: false,
    color: 'light',
    size: 50,
    speed: '0.8s'
};

export default LoadingIndicator;
