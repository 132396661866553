import React, {useState} from 'react';
import BillingHistoryDetailDialog from './BillingHistoryDetailDialog';

const BillingHistoryDisplay = ({list, billingData}) => {

    const [detailDialog, setDetailDialog] = useState({
        ref: null,
        open: false
    });

    const handleDetailOpen = (id) => {
        setDetailDialog({
            ref: id,
            open: true
        });
    };

    const handleDetailClose = () => {
        setDetailDialog({
            ref: null,
            open: false
        });
    };

    const setRows = () => {
        return (
            list.map((item, index) => {
                const creditCard = (item.response.account_type) ? item.response.account_type : 'Card';
                const last4 = item.response?.account_number?.replace(/X/g, '');
                return (
                    <tr
                        key={`history-${item.ref_id}-${index}`}
                        className={`billing-history-row${(item.response.error) ? ' error' : ''}`}
                    >
                        <td className="nowrap">{item.response.addedStr}</td>
                        <td className="nowrap">
                            <button className="link text-link"
                                onClick={() => handleDetailOpen(item.ref_id)}>
                                {item.ref_id}
                            </button>
                        </td>
                        <td>
                            {creditCard} ending in {last4}
                        </td>
                        <td className="xs align-right">{item.response.amountStr}</td>
                        {item.response.error &&
                        <td>
                            Error: {item.response.error_description}
                        </td>
                        }
                        {!item.response.error &&
                        <td>
                            {item.response.messages_message_text}
                        </td>
                        }
                    </tr>
                )
            })
        )
    };

    if (!list || list.length === 0) {
        return (
            <section className="section text-center">
                <div className="container">
                    <p>
                        You have no previous transactions.
                    </p>
                </div>
            </section>
        )
    }

    return (
        <>
            <section className="section pt-4 pb-0">
                <div className="container">
                    <div className="table-container wide history">
                        <table className="fixed-header billing-history">
                            <thead>
                            <tr>
                                <th className="nowrap">Date</th>
                                <th className="nowrap">Reference</th>
                                <th>Credit Card</th>
                                <th className="xs">Amount</th>
                                <th>Response</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[...setRows()]}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <BillingHistoryDetailDialog
                open={detailDialog.open}
                billingData={billingData}
                refId={detailDialog.ref}
                onCloseFunc={() => handleDetailClose()}
            />
        </>
    )
};

export default BillingHistoryDisplay;