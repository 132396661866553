import React from 'react';

const IconCheck = () => {
    return (
        <span className="icon success">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="0 0 115.2 115.2" enableBackground="new 0 0 115.2 115.2"
                 xmlSpace="preserve">
                <polygon fillRule="evenodd" clipRule="evenodd" points="28.729,34.138 49.368,67.454 84.333,3.042 115.199,16.945 67.113,110.242
	37.307,112.158 0.001,51.883 "/>
            </svg>
        </span>
    )
};

export default IconCheck;
