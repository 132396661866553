import React, {useCallback} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import {getSubscriptionStatus} from '../utils/accountFunctions';
import AccountStatusDisplay from '../partials/AccountStatusDisplay';
import LoadingIndicator from '../partials/LoadingIndicator';
import SubscriptionDisplay from '../partials/SubscriptionDisplay';
import SubscriptionsDisplay from '../partials/SubscriptionsDisplay';

const Home = (props) => {

    const {keycloak} = useKeycloak();

    // Handle Keycloak authentication if User is not logged in
    const handleLoginRedirect = useCallback(() => {
        keycloak?.login({
            action: 'login',
            redirectUri: window.location.origin + '/#/'
        });
    }, [keycloak]);

    // Set User permissions and authenticated state
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);
    const userName = (isAuthenticated)
        ? (props.user && props.user.given_name)
            ? props.user.given_name
            : 'User' : 'Visitor';

    // Check for authentication errors or other messaging
    const accessStatus = props.account?.accessStatus;
    if (accessStatus) {
        let accessMessage = 'There was an issue logging into GPULab. Please log out and try again.';
        if (accessStatus === 'access_denied') {
            accessMessage = 'Your account was unable to sign in. Please log out and try again, accepting the Terms of Service.';
        }
        return (
            <>
                <section className="section mt-4 pt-4 pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xs-12 text-center vertical-center">
                                <p className="lead">
                                    Welcome to the <strong>GPULab Portal</strong>.
                                </p>
                                <p>
                                    {accessMessage}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    // Display loading until Keycloak is initialized
    if (!props.keycloakReady) {
        return (
            <>
                <section className="section">
                    <div className="container text-center">
                        <div className="content">
                            <LoadingIndicator color="dark" centered={true} size={48} active={true}/>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    // If User is logged out, handle Keycloak login
    if (!isAuthenticated && props.tokens.noToken === true) {
        handleLoginRedirect();
    }

    // Display nothing while redirect is processing
    if (!isAuthenticated) {
        return (
            <>
            </>
        )
    }

    // Set content based on Subscription existence and status
    const user = props.user;
    const billing = props.account.billing;
    const billingStatus = (billing.billing_status) ? billing.billing_status : null;
    const subscription = (props.account?.subscription) ? props.account.subscription : null;
    const status = getSubscriptionStatus(props.account.subscription);
    let statusStr = '';
    if (!subscription.username) {
        statusStr = '';
    } else if (status.expired) {
        statusStr = 'Your ' + status.plan + ' is expired. Would you like to renew?';
    } else if (!status.expired) {
        statusStr = 'Your ' + status.plan + ' is active until ' + status.activeUntilStr + '.';
    }

    return (
        <>
            <section className="section mt-4 pt-4 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12 text-center vertical-center">
                            <p className="lead">
                                Welcome to the <strong>GPULab Portal</strong>, {userName}.
                                {(user.preferred_username)
                                    ? ' Your username is ' + user.preferred_username + '.'
                                    : ''}
                            </p>

                            {!subscription.username &&
                            <p>You do not have an active subscription.<br/>
                            Would you like to create one for yourself or another User?</p>
                            }
                            {subscription &&
                            <p>{statusStr}</p>
                            }
                            {!status.expired &&
                                <p>
                                    Click the <strong>Launch GPULab Environment</strong> button above to get started.
                                </p>
                            }

                            {/* No Subscription: Link to Subscribe */}
                            {(!billingStatus || !subscription.username) &&
                            <Link to="/subscribe" title="Choose a Plan" className="btn btn-primary">
                                Choose a Plan
                            </Link>
                            }

                        </div>
                    </div>
                </div>
            </section>

            {billing.status === 'Error' &&
            <AccountStatusDisplay billingStatus={billingStatus}/>
            }

            {billingStatus === 'Ok' && subscription.subscription_type &&
            <SubscriptionDisplay data={subscription}/>
            }

            {billing?.subscriptions &&
            <SubscriptionsDisplay list={billing.subscriptions} includeSelf={true} username={user.preferred_username}/>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        account: state.account,
        keycloakReady: state.keycloakReady,
        tokens: {
            token: tokens.token,
            noToken: tokens.noToken
        },
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(Home)
);