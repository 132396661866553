/**
 * Format Credit Card data
 *
 * @param oldValue
 * @param newValue
 * @returns {string|*}
 */
export function cardNumberFormatter(oldValue, newValue) {
    // Do not format if user is deleting
    if (oldValue.length > newValue.length) {
        return newValue;
    }
    return newValue
        .replace(/\W/gi, '')
        .replace(/(.{4})/g, '$1 ')
        .substring(0, 19);
}

/**
 * Format Credit Card Expiration data
 *
 * @param oldValue
 * @param newValue
 * @returns {string|*}
 */
export function expirationDateFormatter(oldValue, newValue) {
    // Do not format if user is deleting
    if (oldValue.length > newValue.length) {
        return newValue;
    }
    return newValue
        .replace(/\W/gi, '')
        .replace(/(.{4})/g, '$1-')
        .substring(0, 7);
}