import React from 'react';
import {connect} from 'react-redux';
import APIComponents from '../utils/APIComponents';
import Analytics from '../utils/Analytics';
import AppFooter from './Footer';
import AppHeader from './Header';
import RouteHandler from '../RouteHandler';

function AppLayout(props) {
    return (
        <div className="page">
            <AppHeader/>
            <div className="content">
                <RouteHandler/>
            </div>
            <AppFooter/>
            <APIComponents ready={props.keycloakReady} user={props.user}/>
            <Analytics configParams={props.configParams}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        user: state.user
    };
};

export default connect(
    mapStateToProps
)(AppLayout);