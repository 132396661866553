import React from 'react';

const IconInfo = () => {
    return (
        <span className="icon info">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="0 0 115.2 115.2" enableBackground="new 0 0 115.2 115.2"
                 xmlSpace="preserve">
                <path fillRule="evenodd" clipRule="evenodd" d="M65.064,28.558c0-0.936-0.112-3.154-0.936-4.203
	c-0.459-0.58-2.331-1.507-5.129-1.507c-2.686,0-6.525,1.048-8.05,2.443c-0.814,1.048-0.927,3.969-0.693,5.364
	c0.468,1.984,1.395,4.324,3.492,5.138c1.638,0.702,7.235,0,8.284-0.814C63.791,33.575,65.064,30.889,65.064,28.558L65.064,28.558z
	 M57.596,0.007c15.909,0,30.301,6.447,40.732,16.871c10.416,10.424,16.871,24.816,16.871,40.725
	c0,15.905-6.455,30.296-16.871,40.719c-10.423,10.425-24.823,16.871-40.732,16.871c-15.9,0-30.301-6.446-40.725-16.871
	C6.447,87.898,0,73.507,0,57.602c0-15.909,6.447-30.301,16.871-40.725S41.696,0.007,57.596,0.007L57.596,0.007z M88.305,26.903
	c7.85,7.85,12.719,18.707,12.719,30.699c0,11.992-4.869,22.845-12.719,30.696c-7.859,7.867-18.717,12.719-30.709,12.719
	s-22.84-4.852-30.699-12.719c-7.859-7.851-12.72-18.704-12.72-30.696c0-11.992,4.861-22.849,12.72-30.699
	c7.859-7.859,18.707-12.72,30.699-12.72S80.445,19.043,88.305,26.903L88.305,26.903z M71.599,88.514
	c-1.984-0.354-3.267-0.701-3.969-0.936c-1.508-0.58-2.097-1.282-2.21-4.783c-0.121-5.129-0.121-13.755-0.121-19.587
	c0-6.542,0.121-15.986,0.121-19.834c-5.719,2.444-17.269,4.203-19.834,4.203c-0.234,0.347-0.346,2.443-0.112,2.912
	c2.452,0.936,3.146,1.403,4.081,2.339c0.702,0.693,0.814,2.097,0.927,3.146c0.121,1.75,0.121,6.879,0.121,10.961
	c0,5.48,0,13.417-0.121,15.974c-0.113,2.807-0.459,4.211-2.097,4.791c-1.161,0.347-2.452,0.581-4.081,0.814
	c-0.234,0.347-0.234,2.678,0.122,3.146c2.798,0,6.992-0.347,13.413-0.347c6.412,0,10.615,0.347,13.179,0.347
	C71.599,91.191,71.72,88.982,71.599,88.514z"/>
            </svg>
        </span>
    )
};

export default IconInfo;
