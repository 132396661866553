import React, {useState} from 'react';
import cardValidator from 'card-validator';
import {cardNumberFormatter, expirationDateFormatter} from '../../utils/billingFunctions';
import BaseInput from '../fields/BaseInput';
import CreditCardLogo from '../CreditCardLogo';
import FormError from './FormError';

export default function CardFields({
                                       register,
                                       errors,
                                       formState,
                                       getValues,
                                       defaultValues,
                                       changeFunc,
                                       isRequired = true
                                   }) {

    const [cardNumber, setCardNumber] = useState('');
    const [cardType, setCardType] = useState('');
    const billing_card_exp = getValues('billing_card_exp');

    const [expiration, setExpiration] = useState((billing_card_exp) ? billing_card_exp : '');
    const [cvvLength, setCVVLength] = useState(3);

    const req = (isRequired) ? ' *' : '';

    const handleFieldChange = (e) => {
        if (e) {
            if (e.name === 'billing_card') {
                const cardNum = cardNumberFormatter(cardNumber, e.value);
                const card = cardValidator.number(cardNum);
                const isAmex = card?.card?.type === 'american-express';
                setCardType((card?.card?.type) ? card.card.type : '');
                setCVVLength((isAmex) ? 4 : 3);
                setCardNumber(cardNum);
                if (typeof changeFunc === 'function') {
                    changeFunc('billing_card', cardNum);
                }
            }
            if (e.name === 'billing_card_exp') {
                const exp = expirationDateFormatter(expiration, e.value);
                setExpiration(exp);
                if (typeof changeFunc === 'function') {
                    changeFunc('billing_card_exp', exp);
                }
            }
            if (e.name === 'billing_card_code') {
                if (typeof changeFunc === 'function') {
                    changeFunc('billing_card_code', e.value);
                }
            }
        }
    };

    return (
        <>
            <div className="row">

                {/* Card Number */}
                <div className="col col-xs-12 col-md-4">
                    <div className="form-group has-image">
                        <BaseInput
                            type="text"
                            id="billing-card" name="billing_card"
                            label={`Card Number${req}`} placeholder={`Card Number${req}`}
                            aria-describedby={(isRequired) ? 'error-billing_card-required' : undefined}
                            aria-invalid={errors.billing_card ? 'true' : 'false'}
                            ref={register({
                                required: (isRequired)
                                    ? (<FormError id="error-billing_card-required" text="Card Number is required"/>)
                                    : undefined,
                                maxLength: {
                                    value: 19
                                }
                            })}
                            maxLength={19}
                            required={(isRequired)}
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input credit-card ${errors.billing_card ? 'input-error' : ''}`}
                        >
                            {isRequired && errors.billing_card && errors.billing_card.message}
                            <span className="card">
                                <CreditCardLogo card={cardType}/>
                            </span>
                        </BaseInput>
                    </div>
                </div>

                {/* Card Expiration */}
                <div className="col col-xs-6 col-md-3 col-lg-2">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-card-exp" name="billing_card_exp"
                            label={`Expires YYYY/MM${req}`} placeholder={`Expires YYYY/MM${req}`}
                            aria-describedby={(isRequired) ? 'error-billing_card_exp-required' : undefined}
                            aria-invalid={errors.billing_card_exp ? 'true' : 'false'}
                            ref={register({
                                required: (isRequired)
                                    ? (<FormError id="error-billing_card_exp-required" text="Required"/>)
                                    : undefined,
                                maxLength: {
                                    value: 7
                                }
                            })}
                            pattern="[0-9]{4}-(0[1-9]|1[012])"
                            maxLength={7}
                            required={(isRequired)}
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_card_exp ? 'input-error' : ''}`}
                        >
                            {isRequired && errors.billing_card_exp && errors.billing_card_exp.message}
                        </BaseInput>
                    </div>
                </div>

                {/* CVV Code */}
                <div className="col col-xs-6 col-md-2">
                    <div className="form-group">
                        <BaseInput
                            type="text"
                            id="billing-card-code" name="billing_card_code"
                            label={`CVV${req}`} placeholder={`CVV${req}`}
                            aria-describedby={(isRequired) ? 'error-billing_card_code-required' : undefined}
                            aria-invalid={errors.billing_card_code ? 'true' : 'false'}
                            ref={register({
                                required: (isRequired)
                                    ? (<FormError id="error-billing_card_code-required" text="Required"/>)
                                    : undefined,
                                maxLength: {
                                    value: cvvLength
                                }
                            })}
                            maxLength={cvvLength}
                            required={(isRequired)}
                            changeFunc={(e) => handleFieldChange(e)}
                            className={`form-input ${errors.billing_card_code ? 'input-error' : ''}`}
                        >
                            {isRequired && errors.billing_card_code && errors.billing_card_code.message}
                        </BaseInput>
                    </div>
                </div>
            </div>
        </>
    )
}