import React, {forwardRef} from 'react';

const BaseInput = forwardRef(
    (
        {id, label, name, placeholder, instructions, className, changeFunc, children, defaultValue, required, disabled, value, maxLength, pattern, type = 'text'}, ref) => {
        return (
            <label htmlFor={id}>
                {label &&
                <span className="label">
                    {label}
                    {required &&
                    <span className="required">*</span>
                    }
                </span>
                }
                {instructions &&
                <span className="instructions">{instructions}</span>
                }
                <input
                    type={type}
                    id={id}
                    name={name}
                    aria-label={placeholder}
                    placeholder={placeholder}
                    value={(value) ? value : undefined}
                    ref={ref}
                    required={(required) ? true : undefined}
                    disabled={(disabled) ? true : undefined}
                    maxLength={(maxLength) ? maxLength : undefined}
                    pattern={(pattern) ? pattern : undefined}
                    className={className}
                    onChange={(changeFunc)
                        ? (e) => changeFunc({
                            name: e.target.name,
                            value: e.target.value
                        })
                        : undefined}
                />
                {children}
            </label>
        );
    }
);

export default BaseInput;
