/**
 * API Resources
 *
 * Billing
 * Subscription
 * SubscriptionType
 *
 */
export const RESOURCES = {
    'Billing': {
        GET_ONE: function () {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/account/billing'
            }
        },
        POST: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/account/billing'
            }
        }
    },
    'BillingHistory': {
        GET_LIST: function () {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/account/billing/history'
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/account/billing/ref/' + params.id
            }
        },
    },
    'PublicKey': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/auth/publicKey'
            }
        }
    },
    'Recurring': {
        POST: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/account/recurring'
            }
        }
    },
    'Subscription': {
        GET_ONE: function () {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/account/subscription'
            }
        },
        POST: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/account/subscribe'
            }
        }
    }
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_ONE
     * @param {string} resource Resource name, e.g. "Subscription
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}
