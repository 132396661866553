import React from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import AccountStatusDisplay from '../partials/AccountStatusDisplay';
import SubscriptionDisplay from '../partials/SubscriptionDisplay';
import SubscriptionsDisplay from '../partials/SubscriptionsDisplay';
import PageTitle from '../partials/PageTitle';

const Account = (props) => {

    const {keycloak} = useKeycloak();
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);

    if (!isAuthenticated) {
        return (
            <div/>
        )
    }

    // Display links based on Billing status
    const user = props.user;
    const billing = props.account.billing;
    const billingStatus = billing?.billing_status ?? '';
    const btnText = (billingStatus === 'Ok') ? 'Billing and Payment Information' : 'Add Billing and Payment Information';

    return (
        <>
            <PageTitle title="Account Status" classes="account"/>

            <div className="row text-center">
                <div className="col col-xs-12">
                    <Link to="/account/billing" className="btn btn-primary mr ml mb"
                          title={btnText}>
                        {btnText}
                    </Link>
                    {billing.username &&
                    <Link to="/account/history" className="btn btn-primary mr ml mb"
                          title="Billing History">
                        Billing History
                    </Link>
                    }
                </div>
            </div>

            {billingStatus !== '' &&
            <AccountStatusDisplay billingStatus={billingStatus}/>
            }

            {billingStatus === 'Ok' &&
            <SubscriptionDisplay data={props.account.subscription}/>
            }

            {billing?.subscriptions &&
            <SubscriptionsDisplay list={billing.subscriptions} includeSelf={true} username={user.preferred_username}/>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        account: state.account,
        tokens: {
            token: tokens.token
        },
        user: state.user
    };
};

export default connect(
    mapStateToProps
)(Account);
