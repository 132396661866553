import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import Emitter from '../../utils/eventEmitter';
import {RESOURCES as resources} from '../../resources';
import {makePostDataRequest} from '../../utils/postRequest';
import BillingFields from './BillingFields';
import CardFields from './CardFields';
import ContactFields from './ContactFields';
import LoadingIndicator from '../LoadingIndicator';
import '../../css/form.css';

const BillingFormFull = (props) => {

    const billingData = props.account.billing;
    const userData = props.user;
    const requireCardFields = (billingData.billing_status !== 'Ok');
    const last4 = (billingData?.billing_card_last4) ? billingData.billing_card_last4.replace(/X/g, '') : '';
    const subscribersToAdd = props.account?.subscribersToAdd;

    let location = useLocation();

    // Set form defaults based on Billing data
    const billingFormDefaultValues = useMemo(() => ({
        contact_email: billingData?.contact_email ?? userData?.email ?? '',
        contact_full_name: billingData?.contact_full_name ?? userData?.name ?? '',
        contact_phone: billingData?.contact_phone ?? '',
        billing_first_name: billingData?.billing_first_name ?? userData?.given_name ?? '',
        billing_last_name: billingData?.billing_last_name ?? userData?.family_name ?? '',
        billing_email: billingData?.billing_email ?? userData?.email ?? '',
        billing_street: billingData?.billing_street ?? '',
        billing_city: billingData?.billing_city ?? '',
        billing_state: billingData?.billing_state ?? '',
        billing_country: billingData?.billing_country ?? '',
        billing_postal_code: billingData?.billing_postal_code ?? '',
        billing_po: billingData?.billing_po ?? '',
        billing_card: '',
        billing_card_exp: '',
        billing_card_code: '',
        organization_name: billingData?.organization_name ?? ''
    }), [billingData, userData]);

    // Set up form and default values
    const {register, errors, formState, handleSubmit, getValues, clearErrors, setValue, reset} = useForm({
        defaultValues: billingFormDefaultValues,
        mode: 'onBlur'
    });

    // State
    const [billingProcessed, setBillingProcessed] = useState(false);
    const [formStage, setFormStage] = useState('form');
    const [formMessage, setFormMessage] = useState({
        error: false,
        message: null
    });
    const [cardFieldsRequired, setCardFieldsRequired] = useState((requireCardFields));

    // Effect: Update form values if Billing data is received
    useEffect(
        () => {
            const updateFormValues = () => {
                reset(billingFormDefaultValues);
                setCardFieldsRequired((requireCardFields));
                setBillingProcessed(true);
                setFormMessage({error: false, message: null});
            };

            if (!billingProcessed && billingData.billing_status) {
                return updateFormValues();
            }

        }, [billingData, billingFormDefaultValues, requireCardFields, billingProcessed, reset]
    );

    // Check if Card, Expiration or Code values were modified
    const checkCardValues = () => {
        return getValues('billing_card') !== '' || getValues('billing_card_exp') !== '' || getValues('billing_card_code') !== '';
    };

    // Event: Update form display and value with field changes
    const onFieldChange = (field, value) => {
        setFormStage('form');
        setFormMessage({error: false, message: null});

        // Update form value
        if (field) {
            setValue(field, value);

            // If card values have been updated, mark them as required
            const cardValuesModified = checkCardValues();
            if (cardValuesModified && !cardFieldsRequired) {
                setCardFieldsRequired(true);
            }
            if (!cardValuesModified && cardFieldsRequired) {
                setCardFieldsRequired(false);
                clearErrors(['billing_card'], ['billing_card_exp'], ['billing_card_code']);
            }
        }
    };

    // Submit button display
    const disableSubmit = () => {
        return !!(
            formStage === 'saving' ||
            formStage === 'success' ||
            getValues('billing_first_name') === '' ||
            getValues('billing_last_name') === '' ||
            getValues('billing_street') === '' ||
            getValues('billing_city') === '' ||
            getValues('billing_postal_code') === '' ||
            getValues('billing_country') === '' ||
            (getValues('billing_country') === 'US' && getValues('billing_state') === '') ||
            getValues('contact_full_name') === '' ||
            getValues('contact_email') === '' ||
            (cardFieldsRequired && (
                getValues('billing_card') === '' ||
                getValues('billing_card_exp') === '' ||
                getValues('billing_card_code') === ''
            )) ||
            (errors &&
                (
                    errors.contact_full_name ||
                    errors.billing_card ||
                    errors.billing_card_exp ||
                    errors.billing_card_code
                )
            )
        );
    };

    // Event: Handle submission click
    const onSubmit = (data) => {

        if (cardFieldsRequired) {
            // If payment fields are modified and required, format Card number
            if (data.billing_card) {
                data.billing_card = data.billing_card.split(' ').join('');
            }
        } else {
            // Do not submit Payment information if unpopulated and not required
            delete data.billing_card;
            delete data.billing_card_exp;
            delete data.billing_card_code;
        }

        // Remove any empty fields from submission
        for (let key in data) {
            if (data[key] === null || data[key] === '') {
                // Non-required fields may be sent with empty values
                const allowEmpty = ['billing_po', 'billing_state', 'organization_name'];
                if (allowEmpty.indexOf(key) === -1) {
                    delete data[key];
                }
                // if (key !== 'billing_po' && key !== 'organization_name' && key !== 'billing_state') {
                //     delete data[key];
                // }
            }
        }
        // console.log('submission data ', data);

        if (data) {
            setFormStage('saving');
            setFormMessage({error: false, message: null});
            submitForm(data);
        }
    };

    // Post submission
    const submitForm = (data) => {
        const billingRequest = makePostDataRequest(resources, 'Billing', 'POST', data);
        billingRequest.then(result => {
            if (result) {
                let message = '';
                let error = true;
                if (result.data?.status === false) {
                    // Error: authorization error
                    if (result.data.message?.indexOf('authorization error') !== -1) {
                        message = 'Sorry, there was an error authorizing your information. Please review your address and payment information.';
                    } else if (result.data.message) {
                        message = 'Sorry, there was an error saving your Billing information: ' + result.data.message;
                    } else {
                        message = 'Sorry, there was an error saving your Billing information.';
                    }
                    setFormStage('fail');
                } else if (result.status === true) {
                    setFormStage('success');
                    const formContent = (cardFieldsRequired) ? 'Billing and Card information were' : 'Billing information was';
                    message = 'Your ' + formContent + ' successfully saved.';
                    error = false;
                    // Send analytics event
                    const formName = (cardFieldsRequired) ? 'BillingAndPayment' : 'Billing';
                    Emitter.emit('analyticsEvent', {name: 'formSubmission', type: formName});
                }
                setFormMessage({error: error, message: message});
                // Send event to refresh Billing and Subscription data
                Emitter.emit('checkBilling', true);
                Emitter.emit('checkSubscription', true);

                return Promise.resolve(result);
            } else {
                console.log('Save Billing no result: ', result);
                setFormStage('fail');
                setFormMessage({error: true, message: 'Sorry, there was an error saving your Billing information.'});

                // Send event to refresh Billing and Subscription data
                Emitter.emit('checkBilling', true);
                Emitter.emit('checkSubscription', true);
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Save Billing try/catch error: ', error);
            setFormStage('fail');
            setFormMessage({error: true, message: 'Sorry, there was an error saving your Billing information.'});
            // Send event to refresh Billing and Subscription data
            Emitter.emit('checkBilling', true);
            Emitter.emit('checkSubscription', true);
            return Promise.reject('Server Error');
        });
    };

    // If Billing information is successfully saved and users have been added for Subscription:
    if (formStage === 'success' && subscribersToAdd.length > 0) {
        return (
            <Redirect
                to={{
                    pathname: '/subscription',
                    search: '',
                    state: {from: location.pathname}
                }}/>
        )
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/* Fields: Contact */}
                <div className="form-section">
                    <section className="section bg-light mt-0 pt-4 pb-0">
                        <div className="container">
                            <h3>My Contact Information</h3>
                            {requireCardFields &&
                            <p className="instructions">
                                Please fill out Contact Information fields below.
                            </p>
                            }
                            <ContactFields
                                register={register}
                                errors={errors}
                                formState={formState}
                                getValues={getValues}
                                defaultValues={billingFormDefaultValues}
                                changeFunc={onFieldChange}
                            />
                        </div>
                    </section>
                </div>

                {/* Fields: Billing */}
                <div className="form-section">
                    <section className="section mt-0 pt-4 pb-0">
                        <div className="container">
                            <h3>Billing</h3>
                            {requireCardFields &&
                            <p className="instructions">
                                Please fill out all Billing Information fields below.
                            </p>
                            }
                            <BillingFields
                                register={register}
                                errors={errors}
                                formState={formState}
                                getValues={getValues}
                                defaultValues={billingFormDefaultValues}
                                changeFunc={onFieldChange}
                            />
                        </div>
                    </section>
                </div>

                {/* Fields: Card */}
                <div className="form-section">
                    <section className="section bg-light mt-0 pt-4 pb-0">
                        <div className="container">
                            <h3>Card Information</h3>
                            {billingData.status === 'Error' &&
                            <p>
                                There is an issue with your Payment information. Please enter valid credit card
                                information.<br/>
                                Your card will be authorized by <span className="merchant">DEASIL WORKS INC</span>. An
                                amount of $1 USD will be charged and voided.
                            </p>
                            }
                            {billingData.status !== 'Ok' && requireCardFields &&
                            <p>
                                Please enter valid credit card information.<br/>
                                Your card will be authorized by <span className="merchant">DEASIL WORKS INC</span>. An
                                amount of $1 USD will be charged and voided.
                            </p>
                            }
                            {!requireCardFields &&
                            <>
                                <p>
                                    Only update these fields if you are changing your payment information.<br/>
                                    Your card will be authorized by <span className="merchant">DEASIL WORKS INC</span>.
                                    An amount of $1 USD will be charged and voided.
                                </p>
                                <p>
                                    Current card ending in {last4}
                                </p>
                            </>
                            }
                            <CardFields
                                register={register}
                                errors={errors}
                                formState={formState}
                                getValues={getValues}
                                defaultValues={billingFormDefaultValues}
                                changeFunc={onFieldChange}
                                isRequired={(cardFieldsRequired)}
                            />
                        </div>
                    </section>
                </div>

                {/* Submit & Messaging */}
                <section className="section mt-4 pt-0 mb-0 pb-0">
                    <div className="container">
                        <div className="row mb-2 pb-2 vertical-center">
                            <button type="submit" className="btn btn-primary mr ml" title="Submit"
                                    disabled={!!disableSubmit()}
                            >
                                Save Billing Information
                            </button>
                            <LoadingIndicator color="dark" centered={false} size={36} active={formStage === 'saving'}/>
                        </div>

                        <p className={`message-form ${(formMessage.error) ? 'error' : ''}`}>
                            {formMessage.message &&
                            <>{formMessage.message}</>
                            }
                        </p>
                    </div>
                </section>
            </form>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account,
        user: state.user
    };
};

export default connect(
    mapStateToProps
)(BillingFormFull);