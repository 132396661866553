import React from 'react';

const Copyright = (props) => {

    const siteUrl = props?.siteUrl ?? '/';
    const year = new Date().getFullYear();

    return (
        <div className="copyright">
            <span>
                &copy; {year} Deasil Works, Inc. | <a
                href={siteUrl} title="GPULab" rel="noopener noreferrer">
                    GPULab
                </a>
            </span>
        </div>
    )
};

export default Copyright;
