import React, {useEffect, useState} from 'react';
import Emitter from '../utils/eventEmitter';
import {RESOURCES as resources} from '../resources';
import {makePostDataRequest} from '../utils/postRequest';
import {makeDateString} from '../utils/dataFunctions';
import LoadingIndicator from './LoadingIndicator';
import '../css/dialog.css';

const SubscriptionRecurringDialog = ({
                                         subscription,
                                         requestRecurring,
                                         isSelf,
                                         onCancelFunc,
                                         onSavedFunc,
                                         open = false
                                     }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [saveMessage, setSaveMessage] = useState(null);

    useEffect(
        () => {
            const updateOpen = () => {
                setSaving(false);
                setSaved(false);
                setSaveMessage(null);
                setIsOpen(open);
            };
            return updateOpen(open);
        }, [open]
    );

    const handleSubmit = () => {
        setSaving(true);
        const params = [
            {
                username: subscription.username,
                recurring: requestRecurring
            }
        ];
        const recurringRequest = makePostDataRequest(resources, 'Recurring', 'POST', params);
        recurringRequest.then(result => {
            if (result) {
                let message = '';
                if (result.data?.status === false) {
                    message = (result.data.message) ? result.data.message : 'Sorry, there was an issue modifying the Subscription.';
                    setSaving(false);
                } else if (result.status === true) {
                    message = 'The subscription was updated.';
                    // Send event to refresh Billing and Subscription data
                    Emitter.emit('checkBilling', true);
                    if (isSelf) {
                        Emitter.emit('checkSubscription', true);
                    }
                    // Send analytics event
                    const eventName = (requestRecurring) ? 'setRecurring' : 'setNonRecurring';
                    Emitter.emit('analyticsEvent', {name: eventName});
                }
                setSaving(false);
                setSaved(true);
                setSaveMessage(message);
                return Promise.resolve(result);
            } else {
                console.log('Save Recurring no result: ', result);
                setSaving(false);
                setSaved(false);
                setSaveMessage('Sorry, there was an issue saving the Subscription.');
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Save Recurring Error: ', error);
            setSaving(false);
            setSaved(false);
            setSaveMessage('Sorry, there was an issue saving the Subscription.');
            return Promise.reject('Server Error');
        });
    };

    const handleSaved = () => {
        if (typeof onSavedFunc === 'function') {
            onSavedFunc(subscription);
        }
    };

    const handleCancel = () => {
        if (typeof onCancelFunc === 'function') {
            setSaving(false);
            onCancelFunc(subscription);
        }
    };

    const title = (requestRecurring) ? 'Renew Subscription' : 'Cancel Subscription';
    const savingStr = (requestRecurring)
        ? 'Setting Subscription to Auto-Renew...'
        : 'Cancelling Subscription Auto-Renewal...';
    const activeUntilStr = (subscription) ? makeDateString(subscription.active_until) : '';

    return (
        <div className={`dialog ${(isOpen) ? 'open' : ''}`}>
            <div className="overlay" onClick={() => handleCancel()}>
            </div>
            <div className="content">

                <span className="h4">{title}</span>

                {subscription &&
                <>
                    {!requestRecurring &&
                    <>
                        {!isSelf &&
                        <span className="p">
                            Are you sure you want to cancel the Subscription for {subscription.username}?
                        </span>
                        }
                        {isSelf &&
                        <span className="p">
                            Are you sure you want to cancel your Subscription?
                        </span>
                        }
                        <span className="p">
                            The Subscription will expire on {activeUntilStr} and will not renew.
                        </span>
                    </>
                    }
                    {requestRecurring &&
                    <>
                        {!isSelf &&
                        <span className="p">
                            Are you sure you want to set the Subscription for {subscription.username} to recur?
                        </span>
                        }
                        {isSelf &&
                        <span className="p">
                            Are you sure you want to set your Subscription to recur?
                        </span>
                        }
                        <span className="p">
                            The Subscription is currently active until {activeUntilStr} and will automatically renew.
                        </span>
                    </>
                    }
                </>
                }

                <div className="btns">
                    {!saving && !saved &&
                    <>
                        <button className="btn btn-outline-primary btn-sm" onClick={() => handleCancel()}>
                            No
                        </button>
                        <button className="btn btn-primary btn-sm" onClick={() => handleSubmit()}>
                            Yes
                        </button>
                    </>
                    }
                    {saving &&
                    <>
                        <span>{savingStr}</span>
                        <LoadingIndicator color="dark" centered={false} size={36} active={true}/>
                    </>
                    }
                    {saved && saveMessage &&
                    <>
                        <span className="p">
                            {saveMessage}
                        </span>
                        <button className="btn btn-outline-primary btn-sm" onClick={() => handleSaved()}>
                            Close
                        </button>
                    </>
                    }
                </div>
            </div>
        </div>
    )
};

export default SubscriptionRecurringDialog;
