import React from 'react';

const GPULabLogo = () => {
    return (
        <span className="logo">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 width="1065.6px" height="194.4px" viewBox="0 0 1065.6 194.4" enableBackground="new 0 0 1065.6 194.4" 
				 xmlSpace="preserve">
				<g id="GPULab_text_rendered">
					<g>
		<path fill="#1B1B1B" d="M381.312,98.736h25.092v53.568c-7.344,5.505-15.912,9.729-25.704,12.672
			c-9.792,2.942-19.858,4.416-30.192,4.416c-14.554,0-27.677-2.977-39.372-8.928c-11.698-5.952-20.878-14.176-27.54-24.673
			c-6.665-10.493-9.996-22.335-9.996-35.52c0-13.182,3.331-25.023,9.996-35.521c6.662-10.493,15.877-18.72,27.642-24.672
			c11.762-5.951,24.991-8.928,39.679-8.928c11.966,0,22.848,1.857,32.64,5.568c9.792,3.714,18.019,9.152,24.684,16.319L391.513,68.4
			c-10.882-10.239-24.005-15.36-39.372-15.36c-10.066,0-19.007,1.986-26.826,5.952c-7.823,3.969-13.942,9.537-18.36,16.704
			c-4.421,7.17-6.63,15.36-6.63,24.576c0,8.961,2.209,17.025,6.63,24.192c4.418,7.17,10.538,12.801,18.36,16.896
			c7.819,4.098,16.693,6.145,26.622,6.145c11.15,0,20.942-2.305,29.376-6.912V98.736z"/>
		<path fill="#1B1B1B" d="M520.133,38.832c9.18,3.841,16.219,9.346,21.114,16.513c4.896,7.17,7.344,15.681,7.344,25.536
			c0,9.729-2.447,18.21-7.344,25.439c-4.895,7.233-11.934,12.769-21.114,16.608s-19.96,5.76-32.334,5.76h-32.232v38.784h-26.521
			v-134.4h58.753C500.172,33.072,510.953,34.992,520.133,38.832z M512.891,100.656c5.983-4.607,8.976-11.199,8.976-19.775
			c0-8.574-2.993-15.168-8.976-19.776c-5.986-4.608-14.758-6.912-26.316-6.912h-31.008v53.376h31.008
			C498.132,107.568,506.904,105.265,512.891,100.656z"/>
		<path fill="#1B1B1B" d="M578.171,153.744c-11.016-10.431-16.523-25.374-16.523-44.832v-75.84h26.519v74.88
			c0,26.37,12.104,39.553,36.313,39.553c24.072,0,36.107-13.183,36.107-39.553v-74.88h26.112v75.84
			c0,19.458-5.476,34.401-16.422,44.832c-10.949,10.435-26.284,15.648-46.003,15.648
			C604.554,169.393,589.187,164.179,578.171,153.744z"/>
		<path fill="#1B1B1B" d="M713.828,33.072h20.4v117.696h77.315v16.704h-97.716V33.072z"/>
		<path fill="#1B1B1B" d="M894.264,75.12c7.956,6.912,11.935,17.218,11.935,30.912v61.44h-18.564v-13.44
			c-3.264,4.737-7.924,8.353-13.974,10.849c-6.053,2.495-13.229,3.743-21.522,3.743c-12.106,0-21.797-2.751-29.07-8.256
			c-7.277-5.502-10.914-12.734-10.914-21.695c0-8.958,3.468-16.158,10.403-21.601c6.937-5.438,17.952-8.16,33.05-8.16h31.008v-3.647
			c0-7.936-2.448-14.017-7.344-18.24c-4.896-4.224-12.106-6.336-21.625-6.336c-6.395,0-12.648,0.993-18.769,2.976
			c-6.12,1.986-11.29,4.642-15.504,7.969l-8.16-13.824c5.575-4.225,12.24-7.455,19.992-9.696c7.752-2.238,15.979-3.36,24.685-3.36
			C874.849,64.752,886.308,68.208,894.264,75.12z M874.782,149.713c5.438-3.136,9.384-7.585,11.832-13.345V122.16h-30.193
			c-16.594,0-24.888,5.25-24.888,15.744c0,5.121,2.106,9.153,6.324,12.096c4.214,2.946,10.062,4.416,17.544,4.416
			C862.88,154.416,869.341,152.851,874.782,149.713z"/>
		<path fill="#1B1B1B" d="M1015.745,71.28c8.294,4.354,14.79,10.435,19.482,18.24c4.691,7.809,7.037,16.833,7.037,27.072
			c0,10.241-2.346,19.296-7.037,27.168c-4.692,7.872-11.188,13.985-19.482,18.336c-8.297,4.353-17.681,6.527-28.152,6.527
			c-8.025,0-15.37-1.47-22.031-4.416c-6.665-2.942-12.174-7.229-16.524-12.863v16.128H930.27V25.009h19.584v56.063
			c4.351-5.376,9.792-9.438,16.319-12.191c6.528-2.751,13.668-4.129,21.42-4.129C998.064,64.752,1007.448,66.931,1015.745,71.28z
			 M1004.627,147.984c5.508-3.006,9.859-7.229,13.056-12.672c3.194-5.439,4.795-11.679,4.795-18.72
			c0-7.038-1.601-13.278-4.795-18.721c-3.196-5.438-7.548-9.63-13.056-12.575c-5.508-2.943-11.729-4.416-18.666-4.416
			c-6.802,0-12.989,1.473-18.563,4.416c-5.578,2.945-9.93,7.137-13.057,12.575c-3.13,5.442-4.691,11.683-4.691,18.721
			c0,7.041,1.562,13.28,4.691,18.72c3.127,5.442,7.479,9.666,13.057,12.672c5.574,3.009,11.762,4.512,18.563,4.512
			C992.897,152.496,999.119,150.993,1004.627,147.984z"/>
					</g>
				</g>
				<g id="arrows">
					<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="131.528" y1="96.323" x2="180.738" y2="96.468"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="189.252,96.374 179.173,89.91 179.173,102.87 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="37.766" y1="48.713" x2="101.869" y2="137.189"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="106.95,144.023 106.254,132.068 95.769,139.686 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="52.576" y1="62.4" x2="96.487" y2="40.188"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="104.032,36.239 92.116,35.057 98,46.604 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="52.644" y1="130.265" x2="96.425" y2="152.735"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="104.054,156.517 98.008,146.181 92.124,157.729 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="55.366" y1="61.886" x2="97.911" y2="86.616"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="105.331,90.792 99.834,80.154 93.354,91.378 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="55.299" y1="130.779" x2="97.988" y2="106.299"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="105.314,101.961 93.354,101.402 99.834,112.626 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="147.992" y1="47.001" x2="184.465" y2="80.037"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="190.855,85.665 187.691,74.116 179.019,83.747 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="147.907" y1="145.674" x2="184.574" y2="112.854"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="190.84,107.086 179.024,109.027 187.696,118.657 	"/>
	
		<line fill="none" stroke="#1B1B1B" strokeWidth="1.8" strokeLinejoin="round" strokeMiterlimit="10" x1="37.653" y1="143.993" x2="101.99" y2="55.687"/>
	<polygon fillRule="evenodd" clipRule="evenodd" fill="#1B1B1B" points="106.919,48.744 95.765,53.1 106.25,60.718 	"/>
				</g>
				<g id="circles_1_">
	<path fillRule="evenodd" clipRule="evenodd" fill="#0AA8A7" d="M157.809,97.215c0,4.604-1.223,9.125-3.525,13.133
		c-2.302,3.979-5.628,7.305-9.608,9.607c-4.008,2.303-8.528,3.523-13.132,3.523c-4.634,0-9.154-1.221-13.162-3.523
		c-3.979-2.303-7.306-5.629-9.608-9.607c-2.303-4.008-3.525-8.529-3.525-13.133c0-4.633,1.222-9.152,3.525-13.161
		c2.302-3.98,5.628-7.306,9.608-9.608c4.008-2.302,8.528-3.524,13.132-3.524c4.634,0,9.153,1.223,13.162,3.524
		c3.979,2.303,7.306,5.628,9.608,9.608C156.586,88.062,157.809,92.582,157.809,97.215z"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#F7941E" d="M242.876,97.215c0,4.604-1.223,9.125-3.525,13.133
		c-2.302,3.979-5.628,7.305-9.608,9.607c-4.008,2.303-8.528,3.523-13.132,3.523c-4.634,0-9.154-1.221-13.162-3.523
		c-3.979-2.303-7.306-5.629-9.608-9.607c-2.303-4.008-3.525-8.529-3.525-13.133c0-4.633,1.222-9.152,3.525-13.161
		c2.302-3.98,5.628-7.306,9.608-9.608c4.008-2.302,8.528-3.524,13.132-3.524c4.634,0,9.153,1.223,13.162,3.524
		c3.979,2.303,7.306,5.628,9.608,9.608C241.654,88.062,242.876,92.582,242.876,97.215z"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#0AA8A7" d="M157.809,37.168c0,4.604-1.223,9.125-3.525,13.133
		c-2.302,3.979-5.628,7.305-9.608,9.607c-4.008,2.303-8.528,3.523-13.132,3.523c-4.634,0-9.154-1.221-13.162-3.523
		c-3.979-2.303-7.306-5.629-9.608-9.607c-2.303-4.008-3.525-8.529-3.525-13.133c0-4.633,1.222-9.152,3.525-13.161
		c2.302-3.98,5.628-7.306,9.608-9.608c4.008-2.302,8.528-3.524,13.132-3.524c4.634,0,9.153,1.223,13.162,3.524
		c3.979,2.303,7.306,5.628,9.608,9.608C156.586,28.016,157.809,32.535,157.809,37.168z"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#0AA8A7" d="M157.809,157.262c0,4.604-1.223,9.125-3.525,13.133
		c-2.302,3.979-5.628,7.305-9.608,9.607c-4.008,2.303-8.528,3.523-13.132,3.523c-4.634,0-9.154-1.221-13.162-3.523
		c-3.979-2.303-7.306-5.629-9.608-9.607c-2.303-4.008-3.525-8.529-3.525-13.133c0-4.633,1.222-9.152,3.525-13.161
		c2.302-3.98,5.628-7.306,9.608-9.608c4.008-2.302,8.528-3.524,13.132-3.524c4.634,0,9.153,1.223,13.162,3.524
		c3.979,2.303,7.306,5.628,9.608,9.608C156.586,148.109,157.809,152.629,157.809,157.262z"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#0AA8A7" d="M62.734,59.184c0,4.604-1.223,9.125-3.525,13.133
		c-2.302,3.979-5.628,7.305-9.608,9.607c-4.008,2.303-8.528,3.523-13.132,3.523c-4.634,0-9.154-1.221-13.162-3.523
		c-3.979-2.303-7.306-5.629-9.608-9.607c-2.303-4.008-3.525-8.529-3.525-13.133c0-4.633,1.222-9.152,3.525-13.161
		c2.302-3.98,5.628-7.306,9.608-9.608c4.008-2.302,8.528-3.524,13.132-3.524c4.634,0,9.153,1.223,13.162,3.524
		c3.979,2.303,7.306,5.628,9.608,9.608C61.511,50.031,62.734,54.551,62.734,59.184z"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#0AA8A7" d="M62.734,130.241c0,4.604-1.223,9.125-3.525,13.133
		c-2.302,3.979-5.628,7.305-9.608,9.607c-4.008,2.303-8.528,3.523-13.132,3.523c-4.634,0-9.154-1.221-13.162-3.523
		c-3.979-2.303-7.306-5.629-9.608-9.607c-2.303-4.008-3.525-8.529-3.525-13.133c0-4.633,1.222-9.152,3.525-13.161
		c2.302-3.98,5.628-7.306,9.608-9.608c4.008-2.302,8.528-3.524,13.132-3.524c4.634,0,9.153,1.223,13.162,3.524
		c3.979,2.303,7.306,5.628,9.608,9.608C61.511,121.089,62.734,125.608,62.734,130.241z"/>
				</g>
            </svg>
        </span>
    )
};

export default GPULabLogo;
