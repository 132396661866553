import React from 'react';
import IconCheck from './images/IconCheck';
import IconIdea from './images/IconIdea';
import IconInfo from './images/IconInfo';
import '../css/aside.css';

const Aside = ({title, content, type = 'note'}) => {
    return (
        <div className={`aside ${type}`}>
            {type === 'error' &&
            <IconInfo/>
            }
            {type === 'note' &&
            <IconIdea/>
            }
            {type === 'success' &&
            <IconCheck/>
            }

            {title &&
            <span className="h4">{title}</span>
            }

            {content &&
            <span className="p">{content}</span>
            }
        </div>
    )
};

export default Aside;
