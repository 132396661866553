import React from 'react';
import Aside from '../partials/Aside';
import TooltipInfo from '../partials/TooltipInfo';
import Tooltip from '../partials/Tooltip';

const TestPrivatePage = () => {
    return (
        <>
            <div className="content not-found">
                <h1 className="page-title">Private</h1>
                <p>
                    You are logged in.<TooltipInfo position="right" width={300} content={`Please note that lorem ipsum dolor sit amet, consectetur adipiscing alit. Please note that lorem ipsum dolor sit amet, consectetur adipiscing alit.`}/>
                </p>
                <p>
                    FPO
                </p>
                <p>
                    FPO with a <span className="tooltip text">Tooltip<Tooltip content={`Lorem ipsum.`}/></span>.
                </p>
                <p>
                    FPO
                </p>
                <p>
                    You are logged in.<TooltipInfo position="top" content={`Lorem ipsum dolor.`} width={300}/>
                </p>
                <Aside type="note" content={`Please note that lorem ipsum dolor sit amet, consectetur adipiscing alit.`}/>
                <Aside type="error" title="Error: Note" content={`Please note that lorem ipsum dolor sit amet, consectetur adipiscing alit.`}/>
                <Aside type="success" title="Error: Note" content={`Please note that lorem ipsum dolor sit amet, consectetur adipiscing alit.`}/>
            </div>
        </>
    )
};

export default TestPrivatePage;
