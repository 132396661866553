import React, {useEffect} from 'react';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import {BrowserRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from './utils/eventEmitter';
import {setUrls} from './store/actions';
import AppLayout from './containers/Layout';
import KeycloakHandler from './utils/KeycloakHandler';
import ScrollHandler from './utils/ScrollHandler';

const App = (props) => {

    const configParams = props.configParams;

    useEffect(
        () => {
            // Store configuration parameters in Redux
            const urls = {
                api_url: configParams.api_url,
                lab_url: configParams.lab_url,
                site_url: configParams.site_url
            };
            props.setUrls(urls);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    /**
     * Process auth events from Keycloak
     *
     * @param event
     * @param error
     */
    const handleKeycloakEvent = (event, error) => {
        // console.log('Keycloak event: ', event);
        // console.log('Keycloak error: ', error);
        if (event === 'onReady') {
            Emitter.emit('keycloakReady', true);
        }
        if (event === 'onAuthSuccess') {
            // console.log('Auth success');
        }
        if (event === 'onAuthRefreshSuccess') {
            // console.log('Auth Refresh success');
        }
        if (event === 'onTokenExpired') {
            // console.log('Auth Token expired');
            Emitter.emit('tokenExpired', true);
        }
        if (event === 'onAuthError') {
            // console.log('Auth error');
            if (error && error.error) {
                Emitter.emit('authError', error.error);
            }
        }
        if (event === 'onAuthLogout') {
            // console.log('Auth logout');
        }
    };

    /**
     * Process tokens event from Keycloak
     *
     * @param event
     * @param error
     */
    const handleKeycloakTokens = (event, error) => {
        // console.log('Keycloak token event: ', event);
        // console.log('Keycloak token error: ', error);
        if (event) {
            if (event.token || event.refreshToken) {
                const tokens = {
                    token: event.token,
                    idToken: event.idToken,
                    refreshToken: event.refreshToken,
                };
                // Notify subscribers of received token
                Emitter.emit('accessToken', tokens.token);
            } else if (event.token === undefined && event.refreshToken === undefined) {
                // Notify subscribers that no token exists (logged-out)
                Emitter.emit('noToken');
            }
        } else if (error) {
            console.log('Keycloak token error: ', error);
        }
    };

    return (
        <ReactKeycloakProvider
            authClient={props.keycloak}
            initOptions={props.keycloakInitConfig}
            onEvent={handleKeycloakEvent}
            onTokens={handleKeycloakTokens}
        >
            <BrowserRouter basename="#">
                <ScrollHandler/>
                <AppLayout configParams={configParams}/>
            </BrowserRouter>

            <KeycloakHandler/>

        </ReactKeycloakProvider>
    );
};

const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        tokens: {
            token: state.tokens.token
        },
        user: {
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setUrls: setUrls
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
