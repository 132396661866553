import React from 'react';
import PageTitle from '../partials/PageTitle';

const NotFound = () => {
    return (
        <>
            <PageTitle title="Not Found" classes="not-found"/>

            <section className="section">
                <div className="container">
                    <p>
                        Sorry, this page could not be calculated.
                    </p>
                </div>
            </section>
        </>
    )
};

export default NotFound;
