import React, {forwardRef} from 'react';

const SelectInput = forwardRef(
    (
        {
            id,
            label,
            placeholder,
            name,
            instructions,
            multiple,
            className,
            options,
            optionPrompt,
            optionSelected,
            changeFunc,
            children,
            required,
            disabled
        }, ref) => {
        return (
            <>
                {instructions &&
                <span className="instructions">{instructions}</span>
                }
                <label htmlFor={id} className="select-label">
                    {label &&
                    <span className="label">
                        {label}
                        {required &&
                        <span className="required">*</span>
                        }
                    </span>
                    }
                    <select
                        id={id}
                        name={name}
                        multiple={(multiple) ? true : undefined}
                        ref={ref}
                        required={(required) ? true : undefined}
                        disabled={(disabled) ? true : undefined}
                        className={className}
                        onChange={(changeFunc)
                            ? (e) => changeFunc({
                                name: e.target.name,
                                value: e.target.value
                            })
                            : undefined}
                        value={(optionSelected !== undefined) ? optionSelected : undefined}
                    >
                        {optionPrompt &&
                        <>
                            <option value="" disabled hidden>{optionPrompt}</option>
                        </>
                        }
                        {options && options.length > 0 &&
                        <>
                            {options.map((option, index) => {
                                return (
                                    <option
                                        key={`select-${id}-option-${option.id}-${index}`}
                                        id={option.id}
                                        value={(option.value !== undefined) ? option.value : option.id}
                                    >
                                        {option.name}
                                    </option>
                                )
                            })}
                        </>
                        }
                    </select>
                    {children}
                </label>
            </>
        );
    }
);

export default SelectInput;
