import React, {useEffect} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from './utils/authFunctions';
import {getLocationElements} from './utils/pageFunctions';

import Account from './routes/Account';
import Billing from './routes/Billing';
import History from './routes/History';
import Home from './routes/Home';
import NotFound from './routes/NotFound';
import Subscribe from './routes/Subscribe';
import Subscription from './routes/Subscription';
import TestPrivatePage from './routes/TestPrivatePage';

/**
 * Return Route or Redirect based on authentication status
 *
 * @param Component
 * @param auth
 * @param exact
 * @param redirect
 * @param rest
 * @return {*}
 * @constructor
 */
const PrivateRoute = ({
                          component: Component,
                          render: Function,
                          auth,
                          redirect,
                          ...rest
                      }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    (auth === true) ? (
                        <Component {...props} {...rest}/>
                    ) : (
                        <Redirect
                            to={{
                                pathname: redirect,
                                state: {from: props.location}
                            }}/>
                    )
                )
            }}
        />
    )
};

/**
 * Function for rendering Routes and PrivateRoutes
 *
 */
const RouteHandler = (props) => {

    const {keycloak} = useKeycloak();
    let history = useHistory();
    let location = useLocation();
    const keycloakReady = props.keycloakReady;
    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);

    // Effect: Store destination for redirection after Keycloak login
    useEffect(
        () => {
            // Set a destination for any route change
            const windowLocation = getLocationElements();
            if (
                windowLocation.route !== '/'
            ) {
                // Store destination route without search queries or Keycloak state
                localStorage.setItem('destination', windowLocation.route);
            }

        }, [location]
    );

    // Effect: Redirect and remove stored location
    useEffect(
        () => {
            const storedDestination = localStorage.getItem('destination');
            if (keycloakReady && isAuthenticated) {
                localStorage.removeItem('destination');

                // If a destination was stored upon Keycloak redirection, redirect to it
                if (storedDestination && storedDestination !== (location.pathname + location.search)) {
                    history.push(storedDestination);
                }
            }

        }, [keycloakReady, history, isAuthenticated, location]
    );

    return (
        <>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/subscribe" component={Subscribe}/>

                <PrivateRoute path="/account/billing" name="Billing" component={Billing}
                              auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/account/history" component={History}
                              auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/account" component={Account}
                              auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/subscription" component={Subscription}
                              auth={isAuthenticated} redirect='/'/>

                <PrivateRoute path="/private" component={TestPrivatePage}
                              auth={isAuthenticated} redirect='/'/>

                <Route path="/" component={Home}/>

                <Route component={NotFound}/>
            </Switch>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(RouteHandler)
);
