import React from 'react';

const IconPrint = () => {
    return (
        <span className="icon print">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" width="115.2px" height="115.2px" viewBox="0 0 115.2 115.2"
                 enableBackground="new 0 0 115.2 115.2" xmlSpace="preserve">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.119,38.001h10.085v15.358h1.925h1.322V2.59V0h2.598h52.565h0.61l0.579,0.29
	l18.934,9.749l1.408,0.72v1.581v41.021h1.581h1.58V38.001h10.453c3.082,18.339,3.646,36.671,0,55.002H96.145V115.2h-0.203H19.451
	V93.003H6.119C1.878,74.977,1.808,56.646,6.119,38.001L6.119,38.001z M26.97,53.359H89.01V17.698l-12.973-0.313l-1.502-0.039
	l-0.203-1.486l-1.174-8.34H26.97V53.359L26.97,53.359z M88.634,82.019H26.97v25.662h61.665V82.019L88.634,82.019z M33.409,17.612
	v4.107h33.506v-4.107H33.409L33.409,17.612z M33.409,36.89v4.107h48.543V36.89H33.409L33.409,36.89z M33.409,27.134v4.107h48.543
	v-4.107H33.409L33.409,27.134z M76.977,9.17l0.657,4.71l8.184,0.195L76.977,9.17L76.977,9.17z M87.257,62.294v5.047h3.693h5.194
	h3.161v-5.047h-3.161h-5.194H87.257z"/>
            </svg>
        </span>
    )
};

export default IconPrint;
