import React from 'react';

const IconUser = () => {
    return (
        <span className="icon user">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="0 0 115.2 115.2" enableBackground="new 0 0 115.2 115.2"
                 xmlSpace="preserve">
                <g>
                    <g>
                        <path
                            d="M57.608-0.001C42.913-0.001,30.9,12.011,30.9,26.707s12.013,26.708,26.708,26.708s26.708-12.012,26.708-26.708 S72.304-0.001,57.608-0.001z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M114.904,80.068c-0.875-2.188-2.041-5.229-3.353-7.125c-6.708-9.914-17.059-15.474-28.72-17.076
			c-1.458-0.146-3.062,0.146-4.229,1.021c-6.124,4.52-13.412,7.969-20.994,7.969c-7.581,0-14.871-3.449-20.993-7.969
			c-1.167-0.875-2.771-1.312-4.228-1.021c-11.663,1.603-22.16,7.162-28.72,17.076c-1.312,1.896-2.479,5.084-3.353,7.125
			c-0.437,0.874-0.292,1.895,0.146,2.77c1.167,2.041,2.624,4.082,3.936,5.831c2.041,2.771,4.228,5.249,6.706,7.582
			c2.041,2.04,4.374,3.935,6.707,5.831c11.517,8.602,25.367,13.12,39.654,13.12c14.287,0,28.138-4.519,39.654-13.12
			c2.333-1.75,4.665-3.791,6.706-5.831c2.332-2.333,4.665-4.812,6.707-7.582c1.458-1.895,2.771-3.79,3.937-5.831
			C115.195,81.962,115.341,80.942,114.904,80.068z"/>
                    </g>
                </g>
            </svg>
        </span>
    )
};

export default IconUser;
