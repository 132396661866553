import React from 'react';
import {isExpired, makeDurationString} from '../utils/accountFunctions';
import {makeDateString} from '../utils/dataFunctions';
import BooleanIcon from './BooleanIcon';

const SubscriptionDisplay = ({data}) => {
    const isBillingAccount = (data.username === data.billing_account);
    const duration = (data.subscription_type) ? makeDurationString(data.subscription_type) : '';
    const expired = (data.active_until) ? isExpired(data.active_until) : true;
    const activeUntilStr = (expired) ? '(Expired)' : makeDateString(data?.active_until);

    return (
        <section className="section mt-0 mb-0 pt-4 pb-0">
            <div className="container">

                <h3>Your Subscription</h3>

                {!data.subscription_type &&
                <div className="row">
                    <div className="col col-xs-12 col-sm-6">
                        <p>You do not have a Subscription.</p>
                    </div>
                </div>
                }

                {data.subscription_type &&
                <div className="table-container wide subscriptions">
                    <table>
                        <thead>
                        <tr>
                            {!isBillingAccount &&
                            <th>Billing Account</th>
                            }
                            <th>Your Username</th>
                            <th>Subscription</th>
                            <th>Duration</th>
                            <th>Active Until</th>
                            <th>Auto-Renew</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {!isBillingAccount &&
                            <td>{data.billing_account}</td>
                            }
                            <td>{data.username}</td>
                            <td>{data.subscription_type?.title}</td>
                            <td>{duration}</td>
                            <td>{activeUntilStr}</td>
                            <td>
                                <BooleanIcon isTrue={data.recurring}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                }
            </div>
        </section>
    )
};

export default SubscriptionDisplay;