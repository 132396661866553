import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import {getEnvironmentConfig} from './utils/dataFunctions';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import './css/index.css';
import './css/themify-icons.css';

let configParams = {
    api_url: '',
    auth_client_id: '',
    auth_realm: '',
    auth_url: '',
    google_analytics_id: '',
    google_measurement_id: '',
    google_tag_manager_id: '',
    lab_url: '',
    site_url: ''
};

localStorage.removeItem('server');
localStorage.removeItem('lab_url');
localStorage.removeItem('site_url');

// Load values from public configuration file
let envConfigRequest = getEnvironmentConfig();

envConfigRequest
    .then(data => {
        if (data) {

            // Set values
            configParams.api_url = data.api_url;
            configParams.auth_client_id = data.auth_client_id;
            configParams.auth_realm = data.auth_realm;
            configParams.auth_url = data.auth_url;
            configParams.google_analytics_id = data.google_analytics_id;
            configParams.google_measurement_id = data.google_measurement_id;
            configParams.google_tag_manager_id = data.google_tag_manager_id;
            configParams.lab_url = data.lab_url;
            configParams.site_url = data.site_url;

            // Store values to use for API calls
            localStorage.setItem('server', configParams.api_url);
            localStorage.setItem('lab_url', configParams.lab_url);
            localStorage.setItem('site_url', configParams.site_url);

            // Create new Keycloak instance with parameters
            const keycloak = new window.Keycloak({
                realm: configParams.auth_realm,
                clientId: configParams.auth_client_id,
                url: configParams.auth_url
            });
            const keycloakInitConfig = {
                onLoad: 'check-sso',
                promiseType: 'native',
            };

            // Initialize Tag Manager
            const gtmId = (configParams.google_tag_manager_id) ? configParams.google_tag_manager_id : null;
            if (gtmId && gtmId !== '' && gtmId.indexOf('-XXX') === -1) {
                const gtmArgs = {
                    gtmId: configParams.google_tag_manager_id,
                    events: {
                        addSubscriber: 'addSubscriber',
                        formSubmission: 'formSubmission',
                        removeSubscriber: 'removeSubscriber',
                        resubscribeUser: 'resubscribeUser',
                        setNonRecurring: 'setNonRecurring',
                        setRecurring: 'setRecurring'
                    }
                };
                TagManager.initialize(gtmArgs);
            }

            // Render the application
            ReactDOM.render(
                <React.StrictMode>
                    <Provider store={store}>
                        <App
                            keycloak={keycloak}
                            keycloakInitConfig={keycloakInitConfig}
                            configParams={configParams}
                        />
                    </Provider>
                </React.StrictMode>,
                document.getElementById('portal')
            );

        } else {
            return Promise.reject('Failed to load Server Configuration');
        }
    })
    .catch(error => {
        console.log('Server Configuration Error: ', error);
        return Promise.reject('Failed to load Server Configuration');
    });

reportWebVitals();
