import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resources';
import {makeGetDataRequest} from './getRequest';
import {setAccountSubscription} from '../store/actions';
import Emitter from './eventEmitter';

/**
 * Component for handling API calls for Subscriptions
 */
const APISubscription = (props) => {

    const ready = props.ready;
    const user = props.user;
    const username = user.preferred_username;
    const setAccountSubscription = props.setAccountSubscription;

    const [processed, setProcessed] = useState(false);

    // Callback: Store User Subscription data
    const processData = useCallback(data => {
        setAccountSubscription(data);
        setProcessed(true);
    }, [setAccountSubscription]);

    // Effect: Get Subscription data for logged-in User
    useEffect(
        () => {
            const getSubscription = () => {
                const request = makeGetDataRequest(resources, 'Subscription', 'GET_ONE');
                request.then(result => {
                    if (!result || result.status === false) {
                        // If user is not found:
                        processData({});
                        return Promise.resolve();
                    } else {
                        // Store data
                        processData(result);
                        return Promise.resolve(result);
                    }
                }).catch(error => {
                    console.log('User Subscriptions Error: ', error);
                    processData({});
                    return Promise.reject('Server Error');
                });
            };

            if (ready && username && !processed) {
                return getSubscription();
            }

        }, [ready, username, processData, processed]);

    // Effect: Refresh Subscription data for logged-in User
    useEffect(
        () => {
            const refreshSubscription = () => {
                setProcessed(false);
            };

            Emitter.on('checkSubscription', refreshSubscription);

            return () => Emitter.off('checkSubscription', refreshSubscription);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    return (
        <>
        </>
    )
};

const mapDispatchToProps = {
    setAccountSubscription: setAccountSubscription,
};

export default connect(
    null,
    mapDispatchToProps
)(APISubscription);