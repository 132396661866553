import React, {useCallback, useEffect, useState} from 'react';
import {RESOURCES as resources} from '../resources';
import {makeGetDataRequest} from '../utils/getRequest';
import {makeDateString, sortArrayByKey, toCurrency} from '../utils/dataFunctions';
import BillingHistoryDisplay from './BillingHistoryDisplay';

const BillingHistory = ({billingData}) => {

    const [historyList, setHistoryList] = useState([]);

    const processData = useCallback(data => {
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (item.response.added) {
                    item.added = item.response.added;
                    item.response.addedStr = makeDateString(item.response.added);
                }
                if (item.response.amount) {
                    item.response.amountStr = '$' + toCurrency(item.response.amount, true);
                }
            }
            data = sortArrayByKey(data, 'added', 'DESC');
        }
        setHistoryList(data);
    }, [setHistoryList]);

    // Effect: Get Billing History data for logged-in User
    useEffect(
        () => {
            const getBillingHistory = () => {
                const request = makeGetDataRequest(resources, 'BillingHistory', 'GET_LIST');
                request.then(result => {
                    if (!result || result.status === false || result.data === null) {
                        processData([]);
                        return Promise.resolve();
                    } else {
                        // Store data
                        processData(result);
                        return Promise.resolve(result);
                    }
                }).catch(error => {
                    console.log('Billing History Error: ', error);
                    processData([]);
                    return Promise.reject('Server Error');
                });
            };

            return getBillingHistory();

        }, [processData]);

    return (
        <BillingHistoryDisplay list={historyList} billingData={billingData}/>
    )
};

export default BillingHistory;
