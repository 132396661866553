import React from 'react';

const BillingHistoryDetailItems = ({list}) => {

    const setRows = () => {
        return (
            list.map((item, index) => {
                return (
                    <tr key={`history-detail-item-${item.itemId}-${index}`} className="bg-none border-none">
                        <td>{item.itemId}</td>
                        <td>{item.name} {item.description}</td>
                        <td className="align-right">{item.unitPrice}</td>
                    </tr>
                )
            })
        )
    };

    const getTotalString = () => {
        let total = 0;
        for (let i = 0; i < list.length; i++) {
            total += parseFloat(list[i].unitPrice);
        }
        return total.toFixed(2);
    };

    if (!list || list.length === 0) {
        return (
            <div/>
        )
    }

    return (
        <div>
            <table className="bg-transparent billing-detail-table">
                <thead>
                <tr className="bg-none border-none">
                    <th>Plan</th>
                    <th>Description</th>
                    <th className="align-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                {[...setRows()]}
                <tr className="bg-none border-none border-top">
                    <td colSpan={2}>
                    </td>
                    <td className="align-right">
                        <strong>Total: {getTotalString()} USD</strong>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
};

export default BillingHistoryDetailItems;