/**
 * Determine if current runtime is local
 *
 * @type {boolean}
 */
export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

/**
 * Load JSON file with environment variables
 *
 * @return {Promise<unknown>}
 */
export async function getEnvironmentConfig() {
    return fetch('/config.json')
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * Check for a blank or invalid value
 *
 * @param term
 * @return {boolean}
 */
export const isBlank = (term) => {
    return (term === undefined || term === null || term === '');
};

/**
 * Update an object via Object.assign
 *
 * @param oldObject
 * @param newValues
 * @return {any}
 */
export const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues)
};

/**
 * Copy an array
 *
 * @param array
 * @return {any}
 */
export const copyArray = (array) => {
    return JSON.parse(JSON.stringify(array))
};

/**
 * Combine two items into an array
 *
 * @param arr1
 * @param arr2
 * @return {*[]}
 */
export const combineArrays = (arr1, arr2) => {
    return [].concat(arr1, arr2);
};

/**
 * Convert bytes to a human-readable string
 *
 * @param bytes
 * @return {string}
 */
export const convertBytesToString = (bytes) => {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)), sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
};

/**
 * Sort array of objects alphanumerically
 *
 * @param arr
 * @param sortField
 * @param sortOrder
 */
export const sortArrayByKey = (arr, sortField, sortOrder = 'ASC') => {
    arr.sort(function (a, b) {
        return a[sortField].toString().toLowerCase().localeCompare(b[sortField].toString().toLowerCase());
    });
    if (sortOrder === 'DESC') {
        arr = arr.reverse();
    }
    return arr;
};

/**
 * Generate a random string for Tags
 *
 * @param prefix
 * @returns {string}
 */
export const getRandomTagKey = (prefix) => {
    const prefixStr = (prefix) ? prefix + '-' : '';
    return prefixStr + Math.floor(Math.random * 1000);
};

/**
 * Format a datetime string from a Date object
 * @param date
 * @return {string}
 */
export const createDateTimeString = (date) => {
    // Format: YYYY-MM-DDTHH:MM:SSZ
    const dateObj = (date) ? date : new Date();
    return dateObj.getFullYear() + '-'
        + padDateString(dateObj.getMonth() + 1) + '-'
        + padDateString(dateObj.getDate()) + 'T'
        + padDateString(dateObj.getHours()) + ':'
        + padDateString(dateObj.getMinutes()) + ':'
        + padDateString(dateObj.getSeconds()) + 'Z';
};

/**
 * Assemble a human-readable date string
 *
 * @param datetime
 * @param order
 * @param withTime
 * @return {string}
 */
export const makeDateString = (datetime, order = 'm', withTime = true) => {
    const dateObj = new Date(datetime);
    const mo = dateObj.getMonth() + 1;
    const dy = dateObj.getDate();
    const yy = dateObj.getFullYear();
    let hr = dateObj.getHours();
    const ampm = (hr > 11) ? 'PM' : 'AM';
    if (hr === 0) {
        hr = 12;
    }
    if (hr > 12) {
        hr -= 12;
    }
    let mn = dateObj.getMinutes();
    let minVal = String('00' + mn).slice(-2);
    let dateStr;
    if (order === 'm') {
        dateStr = mo + '/' + dy + '/' + yy;
    } else if (order === 'd') {
        dateStr = dy + '/' + mo + '/' + yy;
    } else {
        dateStr = mo + '/' + dy + '/' + yy;
    }
    if (withTime) {
        dateStr += ', ' + hr + ':' + minVal + ' ' + ampm;
    }
    if (isNaN(yy) || isNaN(mo)) {
        dateStr = 'No Date';
    }
    return dateStr;
};

/**
 * Pad numbers less than 10 with a leading zero
 *
 * @param num
 * @return {string}
 */
export const padDateString = (num) => {
    return String('00' + num).slice(-2);
};

/**
 * Add commas to numerical strings
 *
 * @param nStr
 * @return {string}
 */
export const addCommas = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1,$2');
    }
    return x1 + x2;
};

/**
 * Pad numbers less than 10 with a leading zero
 *
 * @param num
 * @return {string}
 */
export const addLeadingZero = (num) => {
    return String('00' + num).slice(-2);
};

/**
 * Convert value to number with two decimal places
 *
 * @param value
 * @param fromPennies
 * @return {string}
 */
export const toCurrency = (value, fromPennies = false) => {
    let valueToConvert = value;
    if (fromPennies) {
        valueToConvert = value * 0.01;
    }
    return addCommas(parseFloat(valueToConvert).toFixed(2));
};

/**
 * Convert some Country values for older accounts
 *
 * @param country
 * @returns {string|*}
 */
export const convertCountryCode = (country) => {
    if (country === 'United States' || country === 'USA' || country === 'U.S.' || country === 'U.S.A.') {
        return 'US';
    }
     return country;
};