import {makeDateString} from './dataFunctions';
import {PLANS} from '../data/plans';

/**
 * Determine if a datetime is past
 *
 * @param datetime
 * @returns {boolean}
 */
export const isExpired = (datetime) => {
    return ((new Date().getTime() - new Date(datetime).getTime()) > 0);
};

/**
 * Create a human-readable string for subscription duration
 *
 * @param subscriptionType
 * @returns {string}
 */
export const makeDurationString = (subscriptionType) => {
    if (subscriptionType.duration_days === 1) {
        return '1 Day';
    }
    if (subscriptionType.duration_days === 7) {
        return '1 Week';
    }
    if (subscriptionType.duration_months === 1) {
        return '1 Month';
    }
    if (subscriptionType.duration_years === 1) {
        return '1 Year';
    }
    if (subscriptionType.duration_days > 1) {
        return subscriptionType.duration_days + ' Days';
    }
    if (subscriptionType.duration_months > 1) {
        return subscriptionType.duration_months + ' Months';
    }
};

/**
 * Assemble Subscription status items for current User
 *
 * @param subscription
 * @returns {{activeUntilStr: (string|string), expired: boolean, active: (*|boolean), plan: (*|string)}}
 */
export const getSubscriptionStatus = (subscription) => {
    const active = (subscription?.subscription_type?.active) ? subscription.subscription_type.active : false;
    const expired = (subscription?.active_until) ? isExpired(subscription.active_until) : true;
    const activeUntilStr = (expired) ? '(Expired)' : makeDateString(subscription?.active_until);
    const plan = (subscription?.subscription_type?.title) ? subscription.subscription_type.title : 'plan';
    return {
        active: active,
        activeUntilStr: activeUntilStr,
        expired: expired,
        plan: plan
    };
};

/**
 * Assemble Subscription status for a Managed Subscription
 *
 * @param subscribers
 * @param username
 * @returns {{}}
 */
export const getSubscriberStatus = (subscribers, username) => {
    let status = {
        status: '',
        subscription_type_id: '',
        title: ''
    };
    const idx = subscribers.findIndex(r => r.username === username);
    if (idx !== -1) {
        const subscriber = subscribers[idx];
        status.expired = (subscriber?.active_until) ? isExpired(subscriber.active_until) : true;
        status.status = subscriber.status;
        status.subscription_type_id = subscriber.subscription_type_id;
        const plan = PLANS.find(p => p.id === subscriber.subscription_type_id);
        if (plan) {
            status.title = plan.title;
        }
    }
    return status;
};