import React from 'react';
import GPULabLogo from './images/GPULabLogo';

const AppFooter = (props) => {

    let siteUrl = props?.siteUrl ?? '/';
    if (siteUrl.charAt(siteUrl.length - 1) !== '/') {
        siteUrl += '/';
    }

    return (
        <div className="row nav-footer">

            <div className="col col-xs-12 col-md-3 col-sm-6 mb-6">
                <div className="h3 mb-4">Company</div>
                <ul className="footer-list">
                    <li><a href={`${siteUrl}blog/about-us/`} title="About Us" rel="noopener noreferrer">About Us</a></li>
                    <li><a href={`${siteUrl}contact`} title="Contact" rel="noopener noreferrer">Contact</a></li>
                    <li><a href={`${siteUrl}blog`} title="News & Tutorials" rel="noopener noreferrer">News & Tutorials</a></li>
                </ul>
            </div>

            <div className="col col-xs-12 col-md-3 col-sm-6 mb-6">
                <div className="h3 mb-4">Product</div>
                <ul className="footer-list">
                    <li><a href={`${siteUrl}blog/gpulab-release-jl3-v2.1.x/`} title="Features" rel="noopener noreferrer">Features</a></li>
                    <li><a href={`${siteUrl}pricing`} title="Pricing" rel="noopener noreferrer">Pricing</a></li>
                    <li><a href={`${siteUrl}blog/platform-security-and-data-privacy/`}
                           title="Security" rel="noopener noreferrer">Security</a></li>
                </ul>
            </div>

            <div className="col col-xs-12 col-md-3 col-sm-6 mb-6">
                <div className="h3 mb-4">Support</div>
                <ul className="footer-list">
                    <li><a href={`${siteUrl}faq`} title="FAQ" rel="noopener noreferrer">FAQ</a></li>
                    <li><a href={`${siteUrl}privacy-policy`} title="Privacy Policy" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href={`${siteUrl}terms-conditions`} title="Terms & Conditions" rel="noopener noreferrer">Terms & Conditions</a></li>
                </ul>
            </div>

            <div className="col col-xs-12 col-md-3 col-sm-6 mb-6">
                <a href={siteUrl} title="GPULab" className="logo-footer" rel="noopener noreferrer">
                    <GPULabLogo/>
                </a>
                <p className="mb-4">
                    GPULab is a modern data science platform, providing standards-based,
                    turn-key, GPU-enabled research and development environments.
                </p>
                <ul className="list-inline social-icons">
                    <li>
                        <a href="https://github.com/gpulab" title="GitHub"
                           target="_blank" rel="noopener noreferrer"
                        >
                            <i className="ti-github">
                            </i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/gpulab/" title="Instagram"
                           target="_blank" rel="noopener noreferrer"
                        >
                            <i className="ti-instagram">
                            </i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/gpulabio" title="Twitter"
                           target="_blank" rel="noopener noreferrer"
                        >
                            <i className="ti-twitter-alt">
                            </i>
                        </a>
                    </li>
                    <li>
                        <a href="mailto://info@gpulab.io" title="Email"
                           target="_blank" rel="noopener noreferrer"
                        >
                            <i className="ti-email">
                            </i>
                        </a>
                    </li>

                </ul>
            </div>

        </div>
    )
};

export default AppFooter;
