import React, {useEffect, useState} from 'react';
import {
    useLocation,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from './eventEmitter';
import TagManager from 'react-gtm-module';
import {getLocationElements, getPageTitle} from './pageFunctions';

/**
 * A component to handle Google Tag Manager events
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Analytics = (props) => {

    let location = useLocation();
    const keycloakReady = props.keycloakReady;
    const gtmId = props.configParams.google_tag_manager_id;

    const [hasAnalytics, setHasAnalytics] = useState(false);

    // Effect: Initialize GTM script if a GTM ID is passed from the config
    useEffect(
        () => {
            if (keycloakReady) {
                if (gtmId && gtmId !== '' && gtmId.indexOf('-XXX') === -1) {
                    setHasAnalytics(true);
                }
            }
        }, [keycloakReady, gtmId]
    );

    // Effect: Send Location path events for analytics
    useEffect(
        () => {
            if (keycloakReady && location.pathname.indexOf('&state') === -1 && hasAnalytics) {
                const title = getPageTitle(location.pathname);
                const search = (location.search.indexOf('&state=') === -1) ? location.search : '';
                const url = window.location.origin + '/#' + location.pathname + search;
                const gtmArgs = {
                    dataLayer: {
                        page: location.pathname,
                        pagePath: location.pathname,
                        pageTitle: title,
                        pageUrl: url
                    }
                };
                TagManager.dataLayer(gtmArgs);
            }
        }, [keycloakReady, location.pathname, location.search, hasAnalytics]
    );

    // Effect: Handle user interaction events
    useEffect(
        () => {
            const sendEvent = (evt) => {
                if (evt && hasAnalytics) {
                    const windowLocation = getLocationElements();
                    const title = getPageTitle(windowLocation.route);
                    // Assemble event
                    const dataLayerEvent = {
                        dataLayer: {
                            event: evt.name,
                            pagePath: windowLocation.route,
                            pageTitle: title,
                            pageUrl: windowLocation.href
                        }
                    };
                    // Form submissions include the form name
                    if (evt.name === 'formSubmission') {
                        dataLayerEvent.formType = evt.type;
                    }
                    TagManager.dataLayer(dataLayerEvent);
                }
            };

            // Add a listener
            Emitter.on('analyticsEvent', (e) => sendEvent(e));

            return () => Emitter.off('analyticsEvent', (e) => sendEvent(e));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hasAnalytics]);

    return (
        <>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(Analytics)
);
