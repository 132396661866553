import React from 'react';
import {connect} from 'react-redux';
import NavFooter from '../partials/NavFooter';
import Copyright from '../partials/Copyright';
import '../css/footer.css';

const AppFooter = (props) => {

    const siteUrl = props?.urls?.site_url ?? '/';

    return (
        <>
            <footer>
                <div className="container">
                    <NavFooter siteUrl={siteUrl}/>
                    <Copyright siteUrl={siteUrl}/>
                </div>
            </footer>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        urls: state.urls
    };
};

export default connect(
    mapStateToProps
)(AppFooter);
