import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from '../../utils/eventEmitter';
import {setAccountAddSubscribers} from '../../store/actions';
import {getSubscriberStatus, getSubscriptionStatus} from '../../utils/accountFunctions';
import {copyArray, sortArrayByKey, toCurrency} from '../../utils/dataFunctions';
import AddedSubscriptionsDisplay from '../AddedSubscriptionsDisplay';
import LoadingIndicator from '../LoadingIndicator';
import SelectInput from '../fields/SelectInput';
import {PLANS} from '../../data/plans';

const planOptions = [
    {id: 'none', name: 'Select a Plan:'},
    ...PLANS
];
let defaultPlan = 'none';
let defaultUser = '';

const SubscribeFields = (props) => {

    // Get query string to prepopulate form with user and plan
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let planQuery = query.get('plan');
    let userQuery = query.get('user');
    if (planQuery) {
        const planForDefault = PLANS.find(r => r.tag === planQuery);
        if (planForDefault) {
            defaultPlan = planForDefault.id;
        }
    }
    if (userQuery) {
        defaultUser = userQuery;
    }

    // Get current Subscription status and managed Subscriptions
    const username = (props.user.preferred_username) ? props.user.preferred_username : '';
    const subscription = (props.account.subscription) ? props.account.subscription : null;
    const subscribers = (props.account?.billing?.subscriptions) ? props.account.billing.subscriptions : [];

    // Store value of added susbcribers for populating form
    const addedSubscribers = useMemo(() => (
        props.account.subscribersToAdd
    ), [props.account?.subscribersToAdd]);

    // Set state
    const [subscriptionStatus, setSubscriptionStatus] = useState(getSubscriptionStatus(subscription));
    const [addedSubscriberType, setAddedSubscriberType] = useState((subscriptionStatus.expired) ? 'self' : 'other');
    const [addedUsername, setAddedUsername] = useState((defaultUser !== '') ? defaultUser : username);
    const [addedPlan, setAddedPlan] = useState(defaultPlan);
    const [addedList, setAddedList] = useState([]);
    const [addedListPopulated, setAddedListPopulated] = useState(false);
    const [addedMessage, setAddedMessage] = useState(null);

    // Effect: Set Subscription Status for form field display
    useEffect(
        () => {
            const updateStatus = () => {
                const status = getSubscriptionStatus(subscription);
                setSubscriptionStatus(status);
            };

            if (subscription) {
                return updateStatus();
            }

        }, [subscription]
    );

    // Effect: Reset fields if active Subscription data has loaded
    useEffect(
        () => {
            const updateFields = () => {
                setAddedSubscriberType('other');
                if (defaultUser === '') {
                    setAddedUsername('');
                }
            };

            if (!subscriptionStatus.expired) {
                return updateFields();
            }

        }, [subscriptionStatus]
    );

    // Effect: Populate form if Subscribers to Add are stored
    // (For when user navigates to another route and back)
    useEffect(
        () => {
            const populateAdded = () => {
                setAddedList(addedSubscribers);
                setAddedListPopulated(true);
            };

            if (!addedListPopulated && addedSubscribers.length > 0) {
                return populateAdded();
            }

        }, [addedSubscribers, addedListPopulated, setAddedListPopulated]
    );

    // Effect: Update fields if browser query has changed
    useEffect(
        () => {
            const updateFields = () => {
                const plan = PLANS.find(r => r.tag === planQuery);
                if (plan) {
                    setAddedPlan(plan.id);
                }
                setAddedUsername(userQuery);
            };

            if (planQuery && userQuery) {
                return updateFields();
            }

        }, [planQuery, userQuery]
    );

    useEffect(
        () => {
            if (props.formStage === 'success') {
                setAddedList([]);
            }
            if (props.formStage === 'fail') {
            }

        }, [props.formStage]
    );

    // Handle field update event
    const handleFieldChange = (e) => {
        if (e) {
            const value = e.value;
            // Subscriber Type: Self or Other
            if (e.name === 'subscribe_subscriber_select') {
                setAddedSubscriberType(value);
                setAddedUsername((value === 'self') ? username : '');
                setAddedMessage(null);
                setAddedPlan('none');
            }
            // Username
            if (e.name === 'subscribe_username_add') {
                setAddedUsername(value);
                if (value === username) {
                    setAddedSubscriberType('self');
                    if (!subscriptionStatus.expired) {
                        setAddedMessage('This is your username. Your Subscription is already active.');
                    } else {
                        setAddedMessage('This is your username. This Subscription will be for you.');
                    }
                } else {
                    setAddedMessage(null);
                }
            }
            // Plan
            if (e.name === 'subscribe_plan_add') {
                setAddedPlan(value);
                setAddedMessage(null);
                // Update Username if Subscriber Type is set
                if (addedSubscriberType === 'self' && addedUsername === '') {
                    setAddedUsername(username);
                }
            }
            if (typeof props.onFormChange === 'function') {
                props.onFormChange();
            }
        }
    };

    // Add a User to the Subscribers to Add list
    const handleAdd = () => {
        let allowAdd = true;
        const subscriberIdx = subscribers.findIndex(r => r.username === addedUsername);
        const addedIdx = addedList.findIndex(r => r.username === addedUsername);
        if (subscriberIdx !== -1) {
            const subscriberStatus = getSubscriberStatus(subscribers, addedUsername);
            if (!subscriberStatus.expired) {
                // If Subscriber has an active Subscription:
                allowAdd = false;
                setAddedMessage(addedUsername + ' already has an active Subscription.');
            } else {
                // If Subscriber has an expired Subscription:
                allowAdd = true;
            }
        } else if (addedIdx !== -1) {
            allowAdd = false;
            setAddedMessage(addedUsername + ' has already been added. If you need to select a different plan, click the Remove button.');
        }
        if (allowAdd) {
            // Add the User to the Added list and sort
            const newList = copyArray(addedList);
            const selectedPlan = PLANS.find(r => r.id === addedPlan);
            const planTitle = (selectedPlan) ? selectedPlan.title : addedPlan;
            newList.push({
                cost_string: '$' + toCurrency(selectedPlan.cost_pennies, true),
                cost_pennies: selectedPlan.cost_pennies,
                username: addedUsername,
                subscription_type_id: addedPlan,
                subscription_type_title: planTitle
            })
            const sorted = sortArrayByKey(newList, 'username', 'ASC');
            setAddedList(sorted);
            props.setAccountAddSubscribers(sorted);
            setAddedMessage(null);
            setAddedUsername('');
            setAddedPlan('none');
            // Send analytics event
            Emitter.emit('analyticsEvent', {name: 'addSubscriber'});
        }
    };

    // Remove a User from the Subscribers to Add list
    const handleRemove = (item) => {
        const list = copyArray(addedList);
        const idx = list.findIndex(r => r.username === item.username);
        if (idx !== -1) {
            list.splice(idx, 1);
        }
        const setList = (list.length > 0) ? list : [];
        setAddedList(setList);
        props.setAccountAddSubscribers(setList);
        setAddedMessage(null);
        // Send analytics event
        Emitter.emit('analyticsEvent', {name: 'removeSubscriber'});
    };

    const getAddedTotalString = () => {
        let total = 0;
        for (let i = 0; i < addedList.length; i++) {
            total += parseInt(addedList[i].cost_pennies, 10);
        }
        return '$' + toCurrency(total, true);
    };

    // Handle event for Submit button
    const handleSubmit = () => {
        // Send analytics event (currently encompasses self and others)
        Emitter.emit('analyticsEvent', {name: 'formSubmission', type: 'SubscribeUsers'});

        if (typeof props.onFormSubmit === 'function') {
            props.onFormSubmit();
        }
    };

    // Set Subscriber choices based on active subscription status
    const subscriberOptions = [];
    if (subscriptionStatus.expired) {
        subscriberOptions.push({
            id: 'self',
            name: 'Myself'
        });
    } else {
        if (addedSubscriberType === 'self') {
            setAddedSubscriberType('other');
        }
    }
    subscriberOptions.push({
        id: 'other',
        name: 'Another User'
    });

    return (
        <>
            {!subscriptionStatus.expired &&
            <p>
                Your {subscriptionStatus.plan} Subscription is active until {subscriptionStatus.activeUntilStr}. Would
                you like to add a Subscription for another GPULab User?
            </p>
            }

            <div className="row">
                {/* Select Subscriber */}
                <div className={`col col-xs-12 col-md-3 col-lg-3 ${(!subscriptionStatus.expired) ? 'hidden' : ''}`}>
                    <div className="form-group">
                        <SelectInput
                            id="susbcribe-subscriber-select"
                            name="subscribe_subscriber_select"
                            label="Who is this Subscription for?"
                            options={subscriberOptions}
                            changeFunc={(e) => handleFieldChange(e)}
                            optionSelected={addedSubscriberType}
                            instructions="Who is this Subscription for?"
                            className="form-input"
                        >
                        </SelectInput>
                    </div>
                </div>
                {/* Enter Username */}
                <div className="col col-xs-12 col-md-3 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="subscribe-username-add">
                            <span className="label">
                                Enter a GPULab Username
                            </span>
                            <span className="instructions">Enter a GPULab Username</span>
                            <input
                                type="text"
                                id="subscribe-username-add" name="subscribe_username_add"
                                value={addedUsername}
                                onChange={(e) => handleFieldChange({
                                    name: e.target.name,
                                    value: e.target.value
                                })}
                                className="form-input"
                                disabled={(addedSubscriberType === 'self')}
                            />
                        </label>
                    </div>
                </div>
                {/* Select Plan */}
                <div className="col col-xs-9 col-md-4 col-lg-3">
                    <div className="form-group">
                        <SelectInput
                            id="susbcribe-plan-add"
                            name="subscribe_plan_add"
                            label="Select a Plan"
                            options={planOptions}
                            changeFunc={(e) => handleFieldChange(e)}
                            optionSelected={addedPlan}
                            instructions="Select a Plan"
                            className="form-input"
                        >
                        </SelectInput>
                    </div>
                </div>
                {/* Add to List */}
                <div className="col col-xs-3 col-md-2 col-lg-1">
                    <button className="btn btn-outline-primary btn-sm mt-6"
                            onClick={() => handleAdd()}
                            disabled={(!addedUsername || addedPlan === 'none')}
                    >
                        Add
                    </button>
                </div>
            </div>

            <p className="message-added">
                {addedMessage &&
                <>{addedMessage}</>
                }
            </p>

            <AddedSubscriptionsDisplay list={addedList} onRemoveFunc={handleRemove}/>

            {addedList.length > 0 &&
            <>
                {props.needBilling &&
                <p>
                    After submitting your Billing Information, you will be able to subscribe the added User(s).
                    Your Card will be billed as <span className="merchant">DEASIL WORKS INC</span> for an
                    amount of {getAddedTotalString()} USD.
                </p>
                }
                {!props.needBilling &&
                <p>
                    Your Card will be billed as <span className="merchant">DEASIL WORKS INC</span> for an
                    amount of {getAddedTotalString()} USD.
                </p>
                }
            </>
            }
            <div className="row pb-2 vertical-center">
                {!props.needBilling &&
                <button type="submit" className="btn btn-primary mr ml" title="Subscribe"
                        onClick={() => handleSubmit()}
                        disabled={(!addedList.length)}
                >
                    Subscribe
                </button>
                }
                {props.needBilling &&
                <Link to="/account/billing" title="Continue to Billing"
                      className={`btn btn-primary mr ml${(!addedList.length) ? ' disabled' : ''}`}
                      disabled={(!addedList.length)}
                >
                    Continue to Billing
                </Link>
                }
                <LoadingIndicator color="dark" centered={false} size={36} active={props.formStage === 'saving'}/>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account,
        user: state.user
    };
};

const mapDispatchToProps = {
    setAccountAddSubscribers: setAccountAddSubscribers
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribeFields);