import React from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {logOutUser} from '../store/actions';
import {isExpired} from '../utils/accountFunctions';
import IconUser from './images/IconUser';

const NavHeaderUser = (props) => {

    const isAuthenticated = props.isAuthenticated;
    const accessStatus = props.account?.accessStatus;
    const subscription = props.account?.subscription;
    const siteUrl = props?.urls?.site_url ?? '/';
    const {keycloak} = useKeycloak();

    const handleLogout = () => {
        props.logOutUser();
        keycloak.logout({
            redirectUri: siteUrl
        });
    };

    // Check for authentication errors or other messaging
    if (accessStatus) {
        return (
            <ul className="nav-user">
                <li className="link-user">
                    <button className="btn btn-sm btn-primary has-icon"
                            onClick={handleLogout}
                            title="Sign Out"
                    >
                        <IconUser className="icon user"/>
                        Sign Out
                    </button>
                </li>
            </ul>
        )
    }

    // Display nothing until Keycloak is initialized
    if (!props.keycloakReady) {
        return (
            <ul className="nav-user">
            </ul>
        )
    }

    const expired = (subscription?.active_until) ? isExpired(subscription.active_until) : true;

    return (
        <ul className="nav-user">
            {isAuthenticated &&
            <>
                <li className="link-lab desktop-only">
                    <a href="https://lab.gpulab.io/" title="Launch GPULab Environment"
                       className={`btn btn-sm btn-primary ${(expired) ? ' disabled' : ''}`}
                       target="_blank" rel="noopener noreferrer"
                    >
                        Launch GPULab Environment
                    </a>
                </li>
                <li className="link-user">
                    <button className="btn btn-sm btn-primary has-icon"
                            onClick={handleLogout}
                            title="Sign Out"
                    >
                        <IconUser className="icon user"/>
                        Sign Out
                    </button>
                </li>
            </>
            }
        </ul>
    )
};

const mapStateToProps = (state) => {
    return {
        account: state.account,
        urls: state.urls
    };
};

const mapDispatchToProps = {
    logOutUser: logOutUser
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NavHeaderUser)
);
