import React from 'react';

const IconIdea = () => {
    return (
        <span className="icon idea">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="0 0 115.2 115.2" enableBackground="new 0 0 115.2 115.2"
                 xmlSpace="preserve">
                <path fillRule="evenodd" clipRule="evenodd" d="M57.6,11.246L57.6,11.246v-0.021c-7.623,0-14.523,2.808-19.497,7.348
	c-4.829,4.406-7.812,10.453-7.812,17.086h0.022l0,0h-0.022c0,6.897,4.078,12.637,8.185,18.418c3.982,5.61,7.995,11.249,9.658,18.501
	h3.661l-6.535-27.921v-6h9.745h5.237h9.753v6l-6.544,27.921h3.399c1.678-7.253,5.747-12.906,9.788-18.531
	c4.15-5.781,8.272-11.521,8.272-18.389h-0.022c0-6.648-2.976-12.688-7.79-17.086c-4.975-4.541-11.874-7.348-19.497-7.348V11.246
	L57.6,11.246z M65.236,44.657l-4.588,27.921h-6.053l-4.588-27.921h4.997h5.237H65.236L65.236,44.657z M50.124,111.08
	c4.974,0,9.956,0,14.931,0c1.058,1.511,0.875,2.863,0,4.118c-4.975,0-9.957,0-14.931,0C49.504,113.87,49.54,112.495,50.124,111.08
	L50.124,111.08z M39.583,99.34c11.729,0,23.451,0,35.187,0c2.495,3.571,2.057,6.744,0,9.705c-11.735,0-23.458,0-35.187,0
	C38.125,105.916,38.206,102.67,39.583,99.34L39.583,99.34z M39.583,87.28c11.729,0,23.451,0,35.187,0
	c2.495,3.57,2.057,6.751,0,9.704c-11.735,0-23.458,0-35.187,0C38.125,93.855,38.206,90.617,39.583,87.28L39.583,87.28z M57.6,0.024
	V0.002l0,0V0.024c10.54,0,20.109,3.917,27.046,10.251c7.09,6.47,11.487,15.453,11.487,25.383h-0.021
	c0,10.478-5.179,17.678-10.387,24.932c-4.012,5.591-8.053,11.214-8.433,17.892l-0.299,5.296h-5.296H43.274h-5.302l-0.3-5.288
	c-0.379-6.707-4.369-12.33-8.33-17.914c-5.142-7.24-10.255-14.425-10.255-24.917h-0.021l0,0h0.021
	c0-9.945,4.392-18.928,11.466-25.383C37.491,3.94,47.053,0.024,57.6,0.024z"/>
            </svg>
        </span>
    )
};

export default IconIdea;
