import React from 'react';

const IconMenu = () => {
    return (
        <span className="icon menu">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 width="17" height="17" viewBox="0 0 17 17">
                <g>
                </g>
                <path d="M16 3v2h-15v-2h15zM1 10h15v-2h-15v2zM1 15h15v-2h-15v2z"/>
            </svg>
        </span>
    )
};

export default IconMenu;
