import React, {useCallback} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {Redirect, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';

const Subscribe = (props) => {

    const {keycloak} = useKeycloak();

    let locationSearch = useLocation().search;

    // Handle Keycloak authentication with redirect to Subscription form page
    const handleLoginRedirect = useCallback(() => {
        const searchStr = (locationSearch) ? locationSearch : window.location.search;
        keycloak?.login({
            action: 'login',
            redirectUri: window.location.origin + '/#/subscription' + searchStr
        });
    }, [keycloak, locationSearch]);

    const isAuthenticated = checkAuthentication(props.tokens.token, keycloak.token, keycloak.authenticated);

    // If Keycloak is not initialized:
    if (!props.keycloakReady) {
        return (
            <div/>
        )
    }

    // If not authenticated, handle Keycloak login
    if (!isAuthenticated) {
        handleLoginRedirect();
    }

    // If authenticated, go to Subscription form page
    return (
        <Redirect
            to={{
                pathname: '/subscription',
                search: (locationSearch) ? locationSearch : window.location.search,
                state: {from: props.location}
            }}/>
    )
};

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    return {
        account: state.account,
        keycloakReady: state.keycloakReady,
        tokens: {
            token: tokens.token
        }
    };
};

export default connect(
    mapStateToProps
)(Subscribe);
