import Config from '../config';
import {updateObject} from '../utils/dataFunctions';

const INITIAL_STATE = {
    account: Config.account,
    keycloakReady: false,
    publicKey: Config.publicKey,
    tokens: Config.tokensDefault,
    urls: Config.urls,
    user: Config.user
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            let tokensDefault = updateObject(state.tokens, Config.tokensDefault);
            let userDefault = updateObject(state.user, Config.user);
            return {
                ...state,
                tokens: tokensDefault,
                user: userDefault
            };
        case 'SET_ACCOUNT_ACCESS_STATUS':
            return {
                ...state,
                account: {
                    ...state.account,
                    accessStatus: action.status
                }
            };
        case 'SET_ACCOUNT_ADD_SUBSCRIBERS':
            return {
                ...state,
                account: {
                    ...state.account,
                    subscribersToAdd: action.list
                }
            };
        case 'SET_ACCOUNT_BILLING':
            return {
                ...state,
                account: {
                    ...state.account,
                    billing: action.obj
                }
            };
        case 'SET_ACCOUNT_SUBSCRIPTION':
            return {
                ...state,
                account: {
                    ...state.account,
                    subscription: action.obj
                }
            };
        case 'SET_KEYCLOAK_READY' :
            return {
                ...state,
                keycloakReady: true
            };
        case 'SET_PUBLIC_KEY':
            return {
                ...state,
                publicKey: action.obj
            };
        case 'SET_TOKENS':
            return {
                ...state,
                tokens: action.tokens
            };
        case 'SET_URLS':
            let urls = updateObject(state.urls, action.urls);
            return {
                ...state,
                urls: urls
            };
        case 'SET_USER':
            return {
                ...state,
                user: action.obj
            };
        default:
            return state;
    }
};

export default reducer;
